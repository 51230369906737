import VueXeditable from "./../xeditable/XEditable";
import { mapGetters, mapActions, mapState } from "vuex";
import { getItemFromStorage, setItemInStorage } from "@/_helpers/utility"
import { VueAutosuggest } from "vue-autosuggest";
import overlay from './overlay'
const agreementMixins = {
    components: {
        VueXeditable,
        VueAutosuggest,
        overlay,
    },
    data() {
        return {
            display: false,
            nullValue: null,
            cityOpen: false,
            startHere: true,
            showLocality: true,
            showSociety: true,
            shareUrl: false,
            openModel: "",
            newpackages: [],
            currentPackage: 'Select Package',
            ownerName: "You Name Here",
            textValueDisabled: "This is a disabled editable text.",
            doubeTextValue: "This is also an editable text with double click",
            textareaValue: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            numberValue: "Your age here",
            selectValue: "Gender",
            titlesOptions: ["Title", "Mr", "Mrs","Ms", "Miss" , "M/s", "Dr"],
            relationTo: ["S/o", "D/o", "W/o", "B/o"],
            genderOptions: ["Gender", "Male", "Female"],
            idTypeOptions: ["ID Type", "UID(Aadhaar No.)", "Passport No"],
            countryCodeOptions: ["Country Code", "+91", "+61", "+852", "+968", "+974", "+65", "+971", "+44", "+1", "+27", "+60", "+64", "+66", "+966", "+31", "+973", "+54", "+43", "+880", "+32", "+55", "+86", "+385", "+42", "+45", "+1809", "+20", "+358", "+679", "+33","+34", "+49", "+30", "+592", "+36", "+62", "+353", "+972","+46"],
            plotOption: ["Property Number Type", "House Number", "Survery Number", "Final Plot Number", "Plot Number", "CTS Number"],
            maintenanceOption:["Including Maintenance","Excluding Maintenance"],
            propertyTypeOptions: ["Select"],
            registrationOption: ["Select", "LICENSOR", "LICENSEE", "both"],
            buildUpoption: ["Sq. Ft.", "Sq. Yd.", "Sq. Mt.", "Acre"],
            buildupAreaOption: ["Built-up area", "Carpet area", "Plot area", "Saleable area"],
            houseTypeOption: ["Select", "1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK"],
            executedByOptions: ["Select", "Self", "Power of Attorney"],
            commercialOption: ["Office Space", "Shop", "Office Space in IT/SEZ", "Showroom", "Warehouse", "Commercial Studio"],
            residentialOption: ["Apartment", "Villa", "Builder Floor", "Penthouse", "Independent House", "Residential Studio"],
            getAllLocalities: [],
            getAllSocieties: [],
            prtyinfoActive: false,
            prtybtnActive: false,
            refundableDepositError: false,
            helpBox: "",
            buildingType: "Residential",
            propertyAreaType: "Built-up area",
            propertyType: "",
            flatNo: "",
            builtUparea: "",
            buildUpunit: "Sq. Ft.",
            floorNumber: "",
            buildingName: "",
            propertyLocation: "",
            propertyAddressone:'',
            locality: "",
            propertyCity: "",
            propertyPinCode: "",
            landlordSection: true,
            tenantSection: true,
            finallySubmitting: false,
            panRentValueRequired: false, 
            landlordCount:0,
            tenantCount:0,
            reqData: {
                landlord: [{
                    ownerTitle: "",
                    ownerName: "",
                    ownerAge: "",
                    ownerRelation:'S/o',
                    ownerParentTitle: "",
                    ownerParentName: "",
                    ownerGender: "",
                    ownerCountryCode: "+91",
                    ownerMobile: "",
                    ownerPAN: "",
                    ownerIdProof: "Passport No",
                    ownerUID: "",
                    ownerPassport: "",
                    ownerEmail: "",
                    ownerAddress1: "",
                    ownerAddress2: "",
                    ownerPin: "",
                    ownerCity: "",
                    ownerExecutedBy: "",
                    ownerPOA: []
                }, ],
                tenant: [{
                    tenantName: "",
                    tenantTitle: "",
                    tenantRelation:'S/o',
                    tenantParentTitle: "",
                    tenantParentName: "",
                    tenantAge: "",
                    tenantGender: "",
                    tenantCountryCode: "+91",
                    tenantMobile: "",
                    tenantPAN: "",
                    tenantUID: "",
                    tenantEmail: "",
                    tenantAddress1: "",
                    tenantAddress2: "",
                    tenantPin: "",
                    tenantCity: "",
                }, ],
                witness: [{
                    "witnessType": "Landlord",
                    "witnessName": "",
                    "witnessPhone": "",
                    "witnessEmail": "",
                    "witnessUID": "",
                    "witnessAddress1": "",
                    "witnessAddress2": "",
                    "witnessCity": "",
                    "witnessPin": "",
                    "visible": true
                }, {
                    "witnessType": "Tenant",
                    "witnessName": "",
                    "witnessPhone": "",
                    "witnessEmail": "",
                    "witnessUID": "",
                    "witnessAddress1": "",
                    "witnessAddress2": "",
                    "witnessCity": "",
                    "witnessPin": "",
                    "visible": true
                }],
                contractDuration: "",
                rentamount: [{
                    amount: "",
                    fromMonth: "1",
                    toMonth: ""
                }, ],
                startDate: "",
                amount: "",
                maintenance:"",
                incrementPercentage: "",
                paymentIn: "",
                refundableDeposit: "",
                incrementPercent: "",
                initialRentAmount: "",
                incrEveryMonth: "",
                securityPaymentsMode: [{
                    depositPaymentMode: "",
                    depositSecurityAmount: "",
                    depositTransactionId: "",
                    bankName: "",
                }],
                // maintenance:"",
                lockIn: "",
                noticePeriod: "",
                buildingType: "Residential",
                propertyAreaType: "Built-up area",
                propertyType: "",
                flatNo: "",
                builtUparea: "",
                buildUpunit: "Sq. Ft.",
                floorNumber: "",
                buildingName: "",
                propertyLocation: "",
                propertyAddressone: "",
                locality: "",
                propertyCity: "",
                propertyPinCode: "",
                registrationFeeType: "",
                amenities: [],
                utilities: [],
                removeLockIn: true,
                removeNotice: true,

            },
        };
    },
    
    mounted() {
        setInterval(() => {
            if (this.$route.matched[0].path === '/rentalagreement/:source/:token/:id?') {
                // console.log(this.$route.matched[0].path, this.$route.matched[0].path === '/rentalagreement/:source/:token/:id?')
                this.documentHeight();
            }
        }, 100);

        setInterval(() => {
            if (!this.$route.params.id) {
                let oldData = getItemFromStorage("agreementData");
                oldData.landlord = this.reqData.landlord;
                oldData.tenant = this.reqData.tenant;
                oldData.witness = this.reqData.witness;
                oldData.rentamount = this.reqData.rentamount;
                oldData.securityPaymentsMode = this.reqData.securityPaymentsMode;
                setItemInStorage("agreementData", oldData);
                this.agreementSubmit();
            
            }
        }, 200000);
    },
    props: ["readOnly", "currentCity", "stamp", "agreementId", "esignData","newAgreementAmount"],
    async beforeMount() {
        setTimeout(() => this.startHere = false, 5000);
        this.$root.$on('shareUrl', (data) => {
            this.shareUrl = data;
        });

        if (this.esignData) {
            this.reqData = this.esignData.data;
        } else if (!this.$route.params.id) {
            await this.updateUtilities();
            await this.localData();
            this.progressBar();
            let oldData = getItemFromStorage("agreementData");
            oldData = {...oldData, ...this.reqData };
            setItemInStorage("agreementData", oldData);
        } else {
            let queryData;
            if (this.$route.params.type) {
                queryData = { "id": this.$route.params.id, "type": this.$route.params.type }
            } else {
                queryData = { "id": this.$route.params.id };
            }
            var previewData = await this.previewAgreementInfo(queryData);
            this.reqData = previewData.data;
            if (!this.readOnly) {
                for (var l = 0; l < this.reqData.amenities.length; l++) {
                    for (var k = 0; k < this.masterData.utilities.length; k++) {
                        if (this.masterData.utilities[k].Name == this.reqData.amenities[l].Name) {
                            this.masterData.utilities[k].Quantity = this.reqData.amenities[l].Quantity;
                            this.reqData.amenities.splice(l, 1);
                            l = l - 1;
                            break;
                        }
                    }
                }
                this.reqData.amenities = this.masterData.utilities.concat(this.reqData.amenities);
            }
            this.buildingType = this.reqData.buildingType;
            this.propertyAreaType = this.reqData.propertyAreaType;
            this.propertyType = this.reqData.propertyType;
            this.flatNo = this.reqData.flatNo;
            this.builtUparea = this.reqData.builtUparea;
            this.buildUpunit = this.reqData.buildUpunit;
            this.floorNumber = this.reqData.floorNumber;
            this.buildingName = this.reqData.buildingName;
            this.propertyLocation = this.reqData.propertyLocation;
            this.propertyAddressone = this.reqData.propertyAddressone;
            this.locality = this.reqData.locality;
            this.propertyPinCode = this.reqData.propertyPinCode;
        }
        if (this.reqData.contractDuration == "") {
            this.reqData.contractDuration = this.currentCity !== "Mumbai" && this.currentCity !== "Pune" && this.currentCity !== "Mumbai Rural" && this.currentCity !=="Thane" && this.currentCity !=="Navi Mumbai"&& this.currentCity !=="Pune Rural"? 11 : "";
        }
        this.reqData.propertyCity = this.currentCity;
        this.propertyCity = this.currentCity;
        if (!this.$route.params.id) {
            if ((this.currentCity !== "Mumbai" && this.currentCity !== "Pune" && this.currentCity !== "Mumbai Rural" && this.currentCity !=="Thane" && this.currentCity !=="Navi Mumbai" && this.currentCity !=="Pune Rural") && !this.$route.params.id) {
                this.reqData.contractDuration = 11;
                // this.reqData.lockIn = "11";
            }
            const response = await this.getCities();
            if (response.status) {
                for (var i = 0; i < response.data.Cities.length; i++) {
                    if (response.data.Cities[i].Name == this.currentCity) {
                        const localityResponse = await this.getLocalities(response.data.Cities[i].Id);
                        if (localityResponse.status) {
                            this.getAllLocalities = localityResponse.data;
                        } else {
                            this.getAllLocalities = [];
                        }
                    }
                }
            } else {
                this.getAllLocalities = [];
            }
        }
        let currentUser = getItemFromStorage("user");
        // if (previewData.referredBy == currentUser.Id || (previewData.createdBy > 1 && previewData.createdBy !== currentUser.Id)) {
        // this.$root.$emit('shareMore', true);
        // setTimeout(() => {
        //     this.$router.push({ 'path': '/my-agreement/' + this.$route.params.source + '/' + this.$route.params.token })
        //     window.parent.postMessage({ type: 'myAgreement' }, '*');
        // }, 4000)
        // }
        if (this.$route.query.referrer && parseInt(previewData.referredBy) !== parseInt(currentUser.Id) && previewData.createdBy == 1) {
            const referreData = {
                "referrerId": this.$route.query.referrer,
                "creatorId": currentUser.Id,
                "id": this.$route.params.id
            }
            await this.updateReferrer(referreData);
        }
    },
    computed: {
        ...mapGetters("master", { masterData: "getData" }),
        ...mapState({ state: state => state.agreement }),
        bankNameOptions: function() {
            // if (!this.$route.params.id) {
            if (this.masterData && this.masterData.Banks) {
                const _banks = this.masterData.Banks;
                return ['Select'].concat(_banks.map(m => m.BankName));
            } else {
                return ['Select'];
            }
            // }
        },
      

        depositPaymentOptions: function() {
            // if (!this.$route.params.id) {
            if (this.masterData && this.masterData.paymentModes) {
                const _paymentModes = this.masterData.paymentModes;
                return ['Select'].concat(_paymentModes.map(m => m.RefName));
            } else {
                return ['Select'];
            }
            // }
        },
        todayDate: function() {
            const date = new Date();
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const newMonth = months[date.getMonth()]
            const day = date.getDate();
            const year = date.getFullYear();
            return day + " " + newMonth + " " + year;
        },
        agreementDuration: function() {
            let data = [];
            for (var i = 1; i < 61; i++) {
                if (this.currentCity !== "Mumbai" && this.currentCity !== "Pune" && this.currentCity!=='Mumbai Rural' && this.currentCity !=="Thane" && this.currentCity !=="Navi Mumbai" && this.currentCity!=="Pune Rural") {
                    if (i <= 24)
                        data.push(i.toString());
                } else {
                    data.push(i.toString());
                }
            }
            return data;
        },
        lockInOptions: function() {
            let data = [];
            if (this.reqData.contractDuration !== null) {
                let loop;
                if (this.reqData.contractDuration == undefined) {
                    loop = this.currentCity !== "Mumbai" && this.currentCity !== "Pune" && this.currentCity !== "Mumbai Rural" && this.currentCity !=="Thane" && this.currentCity !=="Navi Mumbai" && this.currentCity!=="Pune Rural"? 11 : 60;
                } else {
                    loop = parseInt(this.reqData.contractDuration);
                }
                for (var i = 1; i < loop + 1; i++) {
                    if (this.currentCity !== "Mumbai" && this.currentCity !== "Pune" || this.currentCity!=='Mumbai Rural' && this.currentCity !=="Thane" && this.currentCity !=="Navi Mumbai" && this.currentCity !=="Pune Rural") {
                        if (i <=12)
                            data.push(i.toString());
                    } else {
                        data.push(i.toString());
                    }
                }
                return data;
            } else {
                return data;
            }
        },
        toMonthOptions: function() {
            // if (this.reqData.rentamount[0].toMonth == "" && this.lockInOptions.length > 1) {
            this.reqData.rentamount[this.reqData.rentamount.length - 1].toMonth = this.lockInOptions[this.lockInOptions.length - 1];
            // }
            return this.lockInOptions;
        },
        localityFilteredOptions() {
            if (!this.$route.params.id) {
                if (this.locality && this.locality.length > 1) {
                    return [{
                        data: this.getAllLocalities.filter(option => {
                            return option.name.toLowerCase().indexOf(this.locality.toLowerCase()) > -1;
                        })
                    }];
                } else {
                    return [];
                }
            } else {
                return []
            }
        },
        societyFilteredOptions() {
            if (!this.$route.params.id) {
                // console.log(this.buildingName)
                if (this.buildingName && this.buildingName.length > 1) {
                    return [{
                        data: this.getAllSocieties.filter(option => {
                            return option.name.toLowerCase().indexOf(this.buildingName.toLowerCase()) > -1;
                        })
                    }];
                } else {
                    return [];
                }
            } else {
                return []
            }
        },
        depositAmountCount() {
            return this.reqData.securityPaymentsMode;
        },
        updateEsignData() {
            if (this.esignData) {
                this.reqData = this.esignData.data;
            }
        },
        ordinalVal: function() {
            let val = this.reqData.paymentIn
            let ordinal = '';
            if (val % 10 == 1 && val != 11) {
                ordinal = 'st'
            } else if (val % 10 == 2 && val != 12) {
                ordinal = 'nd'
            } else if (val % 10 == 3 && val != 13) {
                ordinal = 'rd'
            } else {
                ordinal = 'th'
            }
            return ordinal
        },
        landlordLabel() {
            return this.landlordCount > 1 ? 'Lessors' : 'Lessor';
        },
    
        tenantLabel() {
            return this.tenantCount > 1 ? 'Lessees' : 'Lessee'; 
        }
    },
    methods: {
        ...mapActions('agreement', ["agreementInfo", "previewAgreementInfo", "getCities", "getLocalities", "getSocieties", "updateReferrer" , "verifyCity"]),
        clickHandler(item) {
            console.log(item);
          
        },
       
        checkClauseLength(clauseLength, index, clauseValue) {
            this.reqData.clause[index].clauses = clauseValue;
            this.reqData.clause[index].limit = clauseLength;
        },
        updateIncrementMonths() {
            
            if (parseInt(this.reqData.contractDuration) <= 12) {
                this.reqData.incrementPercent = 0;
                // this.reqData.incrEveryMonth = this.reqData.contractDuration;
                this.reqData.incrEveryMonth = '';
              
            } else {
                this.reqData.incrementPercent = 0;
                // this.reqData.incrEveryMonth = 12
                this.reqData.incrEveryMonth = ''
            }  
            let emptyArray = [];
            let data = {}
            if(this.reqData.initialRentAmount!== ''&& this.reqData.incrEveryMonth!==''){
            for(let i=0;i<parseInt(this.reqData.contractDuration);i++){
                if(i==0){
                    data.amount = this.putAmountCommas(this.reqData.initialRentAmount);
                    data.fromMonth = 1;
                    data.toMonth = parseInt(this.reqData.incrEveryMonth);
                    emptyArray.push(data);
                    i=parseInt(this.reqData.incrEveryMonth)-1;
                }else{
                    let data1 = {}
                    data1.amount = this.putAmountCommas((parseInt((emptyArray[emptyArray.length-1].amount).replace(/,/g, '')) +((emptyArray[emptyArray.length-1].amount).replace(/,/g, '') * parseInt(this.reqData.incrementPercent))/100).toString());
                    data1.fromMonth = i+1;
                    if(parseInt(this.reqData.incrEveryMonth) > parseInt(this.reqData.contractDuration) - i){
                        data1.toMonth = parseInt(this.reqData.contractDuration);
                        emptyArray.push(data1);
                        i = this.reqData.contractDuration;
                    } else {
                        data1.toMonth = i + parseInt(this.reqData.incrEveryMonth);
                        emptyArray.push(data1);
                        i = data1.toMonth - 1;
                    }
                }
            }
            const oldData = getItemFromStorage("agreementData");
            oldData.contractDuration = this.reqData.contractDuration;
            oldData.refundableDeposit = this.reqData.refundableDeposit;
            oldData.initialRentAmount = this.reqData.initialRentAmount;
            oldData.incrementPercent = this.reqData.incrementPercent;
            oldData.incrEveryMonth = this.reqData.incrEveryMonth;
            oldData.rentamount = emptyArray;
            this.reqData.rentamount = emptyArray;
            setItemInStorage("agreementData",oldData)
        }
        },
        updateRentAmount() {
            let emptyArray = [];
            let data = {}
            if(this.reqData.initialRentAmount!== ''&& this.reqData.incrEveryMonth!==''){
                for(let i=0;i<parseInt(this.reqData.contractDuration);i++){
                    if(i==0){
                        data.amount = this.putAmountCommas(this.reqData.initialRentAmount);
                        data.fromMonth = 1;
                        data.toMonth = parseInt(this.reqData.incrEveryMonth);
                        emptyArray.push(data);
                        i=parseInt(this.reqData.incrEveryMonth)-1;
                    }else{
                        let data1 = {}
                        data1.amount = this.putAmountCommas((parseInt((emptyArray[emptyArray.length-1].amount).replace(/,/g, '')) +((emptyArray[emptyArray.length-1].amount).replace(/,/g, '') * parseInt(this.reqData.incrementPercent))/100).toString());
                        data1.fromMonth = i+1;
                        if(parseInt(this.reqData.incrEveryMonth) > parseInt(this.reqData.contractDuration) - i){
                            data1.toMonth = parseInt(this.reqData.contractDuration);
                            emptyArray.push(data1);
                            i = this.reqData.contractDuration;
                        } else {
                            data1.toMonth = i + parseInt(this.reqData.incrEveryMonth);
                            emptyArray.push(data1);
                            i = data1.toMonth - 1;
                        }
                    }
                }
                const oldData = getItemFromStorage("agreementData");
                oldData.contractDuration = this.reqData.contractDuration;
                oldData.refundableDeposit = this.reqData.refundableDeposit;
                oldData.initialRentAmount = this.reqData.initialRentAmount;
                oldData.incrementPercent = this.reqData.incrementPercent;
                oldData.incrEveryMonth = this.reqData.incrEveryMonth;
                oldData.rentamount = emptyArray;
                this.reqData.rentamount = emptyArray;
                setItemInStorage("agreementData",oldData)
            }
            return true;
        },
        progressBar() {
            const allEditableInputs = document.getElementsByClassName("vue-xeditable-form-control");
            for (var i = 0; i < allEditableInputs.length; i++) {
                let attribute = allEditableInputs[i].getAttribute("attribute");
                let index = allEditableInputs[i].getAttribute("index");
                let name = allEditableInputs[i].getAttribute("name");
                // console.log(attribute, name)
                if (allEditableInputs[i].getAttribute("required") == "required" && (allEditableInputs[i].value == "" || allEditableInputs[i].value == null || allEditableInputs[i].value == " ")) {
                    if (attribute == "landlord") {
                        this.$root.$emit('progressBar', { "progressSection": "landlord" + index + name, "show": false, "checkArray": this.reqData.landlord, "type": "landlord" });
                    } else if (attribute == "tenant") {
                        this.tenantSection = false;
                    }
                } else {
                    if (attribute == "landlord") {
                        this.$root.$emit('progressBar', { "progressSection": "landlord" + index + name, "show": true, "checkArray": this.reqData.landlord, "type": "landlord" });
                    } else if (attribute == "tenant") {
                        this.tenantSection = false;
                    }
                }
            }
            // this.$root.$emit('progressBar', { "landlordSection": this.landlordSection, "tenantSection": this.tenantSection });
            // for (var i = 0; i < this.reqData.landlord.length; i++) {
            //     for (var key of Object.keys(this.reqData.landlord[i])) {
            //         console.log(key + " -> " + this.reqData.landlord[i][key])
            //         if (this.reqData.landlord[i][key].length < 1) {
            //             this.landlordSection = false;
            //             break;
            //         }
            //     }
            // }
        },
        documentHeight() {
            // var body = document.body;
            // var html = document.documentElement;
            var bodyH = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
            window.parent.postMessage({ height: bodyH + 'px' }, '*');
        },
        stopEditing(event) {
            const { name } = event.target;
            if (name =='flatNo' || name=='floorNumber' || name=='locality' || name=='buildingName' || name=='propertyCity'|| name=='propertyPinCode'){
                this.propertyAddressone = (this.flatNo!==''?this.flatNo+ ',' :'') + (this.floorNumber!==''?this.floorNumber+ ',':'')+ (this.buildingName!==''?this.buildingName +',':'') + (this.locality!==''?this.locality+',':'') + (this.propertyCity!==''?this.propertyCity+ ',':'') + this.propertyPinCode
            }
            if (event && event.target && event.target.pattern) {
                var pattern = new RegExp(event.target.pattern)
                var check = pattern.test(event.target.value);
                
                if (!check) {
                    event.target.value = "";
                    event.target.placeholder = "Please Enter a Valid Value";
                    event.target.classList.add("properyInfoError");
                } else {
                    event.target.classList.remove("properyInfoError");
                }
            } else {
                const selectV = event.target.value;
                if (selectV) {
                    event.target.classList.remove("properyInfoError");
                } else {
                    event.target.classList.add("properyInfoError");
                }
            }
        },
        async onSelectedLocality(item) {
            this.locality = item.item.name;
            // console.log(item,"====locality")
            const response = await this.getSocieties(item.item.identifier);
            if (response.status) {
                this.getAllSocieties = response.data;
            } else {
                this.getAllSocieties = [];
            }
            this.showLocality = true;
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.locality = this.locality;
                setItemInStorage("agreementData", oldData);
            }
        },
        async onSelectedSociety(item) {
            this.buildingName = item.item.name;
            console.log(item);
            this.showSociety = true;
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.buildingName = this.buildingName;
                setItemInStorage("agreementData", oldData);
            }
        },
        bullringTypeCheck() {
            if (this.propertyType == 'Apartment') {
                document.getElementsByClassName('buildingSet')[0].setAttribute('required', 'required');
            } else {
                document.getElementsByClassName('buildingSet')[0].removeAttribute('required', 'required');
            }
        },
        getPropertyInfo() {
            let info = document.getElementsByClassName('properyInfo');
            for (let i = 0; i < info.length; i++) {
                if (info[i].getAttribute('required') == "required" && info[i].value == "") {
                    console.log(info[i].getAttribute('required'), info[i].value)
                    info[i].classList.add('properyInfoError');
                } else {
                    info[i].classList.remove('properyInfoError');
                    this.prtybtnActive = false;
                }
            }
            if (document.getElementsByClassName('properyInfoError').length < 1) {
                this.reqData.buildingType = this.buildingType;
                this.reqData.propertyType = this.propertyType;
                this.reqData.propertyAreaType = this.propertyAreaType;
                this.reqData.builtUparea = this.builtUparea;
                this.reqData.flatNo = this.flatNo;
                this.reqData.buildUpunit = this.buildUpunit;
                this.reqData.floorNumber = this.floorNumber;
                this.reqData.locality = this.locality;
                this.reqData.buildingName = this.buildingName;
                this.reqData.propertyCity = this.propertyCity;
                this.reqData.propertyPinCode = this.propertyPinCode;
                this.reqData.propertyAddressone = this.propertyAddressone;
                if ("agreementData" in sessionStorage) {
                    var oldData = getItemFromStorage("agreementData");
                    oldData.buildingType = this.reqData.buildingType;
                    oldData.propertyType = this.reqData.propertyType;
                    oldData.propertyAreaType = this.reqData.propertyAreaType;
                    oldData.builtUparea = this.reqData.builtUparea;
                    oldData.flatNo = this.reqData.flatNo;
                    oldData.buildUpunit = this.reqData.buildUpunit;
                    oldData.floorNumber = this.reqData.floorNumber;
                    oldData.locality = this.reqData.locality;
                    oldData.buildingName = this.reqData.buildingName;
                    oldData.propertyCity = this.reqData.propertyCity;
                    oldData.propertyPinCode = this.reqData.propertyPinCode;
                    oldData.propertyAddressone = this.reqData.propertyAddressone;
                    setItemInStorage("agreementData", oldData);
                    this.prtyinfoActive = false;
                    document.getElementById('helpText').setAttribute('style', 'display:none');
                }
            } else {
                this.prtyinfoActive = true;
            }
        },
        isNumeric(event){
            let char = String.fromCharCode(event.keyCode); // Get the character
            if(/^[0-9]+$/.test(char)){return true;} // Match with regex 
            else {event.preventDefault(); return false}
          },
        getPropertyDetails(event) {
            var y = event.clientY;
            y = y - 324;
            document.getElementById('propertyDetails').setAttribute("style", "top:" + y + "px;");
            this.prtyinfoActive = true;
        },
        closePropertyInfo() {
            this.prtyinfoActive = false;
        },
    
        onLocalityInputChange(text) {
            // event fired when the input changes
            console.log(text);
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.locality = this.reqData.locality;
                setItemInStorage("agreementData", oldData);
            }
        },
        onSocietyInputChange(text) {
            // event fired when the input changes
            console.log(text);
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.buildingName = this.reqData.buildingName;
                setItemInStorage("agreementData", oldData);
            }
        },
        /**
         * This is what the <input/> value is set to when you are selecting a suggestion.
         */
        getLocalitySuggestionValue(suggestion) {
            return suggestion.item.name
        },
        getSocietySuggestionValue(suggestion) {
            return suggestion.item.name;
        },
        focusMe(e) {
            console.log(e) // FocusEvent
        },
        updateUtilities() {
            var data = this.masterData;
            this.reqData.amenities = data.utilities;
        },
        localData() {
            var localData = getItemFromStorage("agreementData");
            if (localData) {
                for (var key of Object.keys(localData)) {
                    if (key == "amenities") {
                        for (var j = 0; j < localData[key].length; j++) {
                            for (var i = 0; i < this.reqData.amenities.length; i++) {
                                if (this.reqData.amenities[i].Name == localData[key][j].Name) {
                                    this.reqData.amenities[i].Quantity = localData[key][j].Quantity;
                                    localData[key].splice(j, 1);
                                    j = j - 1
                                    break;
                                }
                            }
                        }
                        this.reqData.amenities = this.reqData.amenities.concat(localData[key]);
                    } else {
                        this.reqData[key] = localData[key];
                    }
                }
                this.buildingType = this.reqData.buildingType;
                this.propertyType = this.reqData.propertyType;
                this.propertyAreaType = this.reqData.propertyAreaType;
                this.builtUparea = this.reqData.builtUparea;
                this.flatNo = this.reqData.flatNo;
                this.buildUpunit = this.reqData.buildUpunit;
                this.floorNumber = this.reqData.floorNumber;
                this.locality = this.reqData.locality;
                this.buildingName = this.reqData.buildingName;
                this.propertyCity = this.reqData.propertyCity;
                this.propertyPinCode = this.reqData.propertyPinCode;
                this.propertyAddressone = this.reqData.propertyAddressone;
            }
        },
        countIncreement(data) {
            var newData = [];
            this.reqData.amenities[data].Quantity = this.reqData.amenities[data].Quantity + 1;
            for (var i = 0; i < this.reqData.amenities.length; i++) {
                if (this.reqData.amenities[i].Quantity > 0) {
                    newData.push(this.reqData.amenities[i]);
                }
            }
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.amenities = newData;
                setItemInStorage("agreementData", oldData);
            } else {
                var storeData = {}
                storeData.amenities = newData;
                setItemInStorage("agreementData", storeData);
            }
        },
        utilityBlurEvent(data, index, e) {
            var newData = [];
            for (var i = 0; i < this.reqData.amenities.length; i++) {
                if (this.reqData.amenities[i].Quantity > 0 && this.reqData.amenities[i].Name !== null) {
                    newData.push(this.reqData.amenities[i]);
                    if (e.target.value !== '') {
                        document.getElementById(data + index).disabled = true
                    }
                }
            }
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.amenities = newData;
                setItemInStorage("agreementData", oldData);
            } else {
                var storeData = {}
                storeData.amenities = newData;
                setItemInStorage("agreementData", storeData);
            }
        },
        countdcreement(data) {
            if (this.reqData.amenities[data].Quantity == 0) return
            var newData = [];
            this.reqData.amenities[data].Quantity = this.reqData.amenities[data].Quantity - 1;
            for (var i = 0; i < this.reqData.amenities.length; i++) {
                if (this.reqData.amenities[i].Quantity > 0) {
                    newData.push(this.reqData.amenities[i]);
                }
            }
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.amenities = newData;
                setItemInStorage("agreementData", oldData);
            } else {
                var storeData = {}
                storeData.amenities = newData;
                setItemInStorage("agreementData", storeData);
            }
        },
        openMenu(data) {
            this.cityOpen = data;
        },
        getcity(data) {
            this.cityOpen = false;
            this.currentCity = data;
        },
        getPackage(myPackage) {
            this.cityOpen = false;
            this.currentPackage = myPackage;
        },
        addamenity() {
            this.reqData.amenities.push({ "Name": "", "Quantity": 1, "Custom": true })
        },
        deleteAmenity(index) {
            this.reqData.amenities.splice(index, 1);
            let newData = [];
            for (var i = 0; i < this.reqData.amenities.length; i++) {
                if (this.reqData.amenities[i].Quantity > 0) {
                    newData.push(this.reqData.amenities[i]);
                }
            }
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.amenities = newData;
                setItemInStorage("agreementData", oldData);
            } else {
                var storeData = {}
                storeData.amenities = newData;
                setItemInStorage("agreementData", storeData);
            }
        },
        removeLanloard(index) {
            this.reqData.landlord.splice(index, 1);
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.landlord = this.reqData.landlord;
                setItemInStorage("agreementData", oldData);
            }
            //.showthis = this.reqData.length - 1;
        },
        removeTenant(index) {
            this.reqData.tenant.splice(index, 1);
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.tenant = this.reqData.tenant;
                setItemInStorage("agreementData", oldData);
            }
        },
        removeRentBreak(index) {
            this.reqData.rentamount.splice(index, 1);
            this.toMonthOptions;
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.rentamount = this.reqData.rentamount;
                setItemInStorage("agreementData", oldData);
                // console.log(oldData)
            }
        },
        removPaymentDeposite(index) {
            this.reqData.securityPaymentsMode.splice(index, 1);
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.securityPaymentsMode = this.reqData.securityPaymentsMode;
                setItemInStorage("agreementData", oldData);
            }
        },
        addLanlordWitness() {
            this.reqData.witness.push({
                "witnessType": "Landlord",
                "witnessName": "",
                "witnessPhone": "",
                "witnessEmail": "",
                "witnessUID": "",
                "witnessAddress1": "",
                "witnessAddress2": "",
                "witnessCity": "",
                "witnessPin": "",
                "visible": true
            });
        },
        addTenantWitness() {
            this.reqData.witness.push({
                "witnessType": "Tenant",
                "witnessName": "",
                "witnessPhone": "",
                "witnessEmail": "",
                "witnessUID": "",
                "witnessAddress1": "",
                "witnessAddress2": "",
                "witnessCity": "",
                "witnessPin": "",
                "visible": true
            });
        },
        addPaymentDeposite() {
            let amountCount = 0;
            console.log(this.reqData.securityPaymentsMode)
            for (let i = 0; i < this.reqData.securityPaymentsMode.length; i++) {
                amountCount = amountCount + parseInt(this.reqData.securityPaymentsMode[i].depositSecurityAmount.replace(/,/g, ''));
            }
            console.log(amountCount)
            console.log(parseInt(this.reqData.refundableDeposit))
            console.log(parseInt(amountCount) < parseInt(this.reqData.refundableDeposit.replace(/,/g, '')))
            if (this.reqData.securityPaymentsMode[this.reqData.securityPaymentsMode.length - 1].depositPaymentMode &&
                this.reqData.securityPaymentsMode[this.reqData.securityPaymentsMode.length - 1].depositSecurityAmount &&
                parseInt(amountCount) < parseInt(this.reqData.refundableDeposit.replace(/,/g, ''))) {
                this.reqData.securityPaymentsMode.push({
                    "depositPaymentMode": "",
                    "depositSecurityAmount": "",
                    "depositTransactionId": "",
                    "bankName": ""
                });
            }
        },
        updateagreement(data) {
            var jsonData = {
                "key": data,
                "value": this.reqData[data]
            }
            if (data == 'cityId') {
                var cities = this.masterData.cities;
                for (var i = 0; i < cities.length; i++) {
                    if (cities[i].Id == this.reqData[data]) {
                        jsonData.value = cities[i].Name;
                    }
                }
            }
            this.$root.$emit('updating', jsonData);
        },
        cityChange(event) {
            this.newpackages.length = 0;
            var cityId = event.target.value;
            var packages = this.masterData.packages;
            var newpackages = [];
            for (var i = 0; i < packages.length; i++) {
                if (packages[i].CityId == cityId) {
                    newpackages.push(packages[i]);
                }
            }
            this.newpackages = newpackages;
        },
        addLandlord() {
            this.reqData.landlord.push({
                ownerTitle: "",
                ownerName: "",
                ownerAge: "",
                ownerParentTitle: "",
                ownerRelation:'S/o',
                ownerParentName: "",
                ownerGender: "",
                ownerCountryCode: "+91",
                ownerMobile: "",
                ownerPAN: "",
                ownerIdProof: "Passport No",
                ownerUID: "",
                ownerPassport: "",
                ownerEmail: "",
                ownerAddress1: "",
                ownerAddress2: "",
                ownerPin: "",
                ownerCity: "",
                ownerExecutedBy: "",
                ownerPOA: []
            });
        },
        addTenant() {
            this.reqData.tenant.push({
                tenantName: "",
                tenantTitle: "",
                tenantParentTitle: "",
                tenantRelation: 'S/o',
                tenantParentName: "",
                tenantAge: "",
                tenantGender: "",
                tenantCountryCode: "+91",
                tenantMobile: "",
                tenantPAN: "",
                tenantUID: "",
                tenantAddress1: "",
                tenantAddress2: "",
                tenantEmail: "",
                tenantPin: "",
                tenantCity: "",
            });
        },
        addClause(data) {
            var item = {
                "clauses": "",
                "limit": 1000,
                "custom": true
            }
            this.reqData.clause.splice(data + 1, 0, item);
        },
        deleteClause(data) {
            var check = confirm("Are you sure you want to delete this clause?");
            if (check) {
                this.reqData.clause.splice(data, 1);
                if ("agreementData" in sessionStorage) {
                    var oldData = getItemFromStorage("agreementData");
                    oldData.clause = this.reqData.clause;
                    setItemInStorage("agreementData", oldData);
                }
            } else {
                return
            }
        },
        // isWitnessRequired(index){
        //     let witness=this.reqData.witness
        //    if(witness[index].witnessName!==''||witness[index].witnessPhone!==''){
        //        return true
        //    }else{
        //        return false
        //    }
        // // },
        // showdiv(index){
        //     console.log(index)
        //    return true

        // },
        removeLockin(id) {
            var check = confirm("Are you sure you want to delete it.");
            if (check) {
                //alert(id);
                document.getElementById(id).remove();
                this.reqData.removeLockIn = false;
                let oldData = getItemFromStorage("agreementData");
                oldData.removeLockIn = this.reqData.removeLockIn;
                setItemInStorage("agreementData", oldData);
            }
        },
        removeNotice(id) {
            var check = confirm("Are you sure you want to delete it.");
            if (check) {
                //alert(id);
                document.getElementById(id).remove();
                this.reqData.removeNotice = false;
                let oldData = getItemFromStorage("agreementData");
                if (oldData[id]) { //check if key exists
                    delete oldData[id]; //remove the key from object
                }
                oldData.removeNotice = this.reqData.removeNotice;
                setItemInStorage("agreementData", oldData);
            }
        },
        filterInput(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        removeWitness(data) {
            //alert(data);
            this.reqData.witness.splice(data, 1);
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                oldData.witness = this.reqData.witness;
                setItemInStorage("agreementData", oldData);
            }
        },
        incByAmount() {
            if (this.reqData.rentamount[this.reqData.rentamount.length - 1].toMonth &&
                this.reqData.rentamount[this.reqData.rentamount.length - 1].amount &&
                this.reqData.rentamount[this.reqData.rentamount.length - 1].fromMonth != "" &&
                parseInt(this.reqData.rentamount[this.reqData.rentamount.length - 1].toMonth) < parseInt(this.reqData.contractDuration)) {
                var lastMonth = parseFloat(this.reqData.rentamount[this.reqData.rentamount.length - 1].toMonth) + 1;
                this.reqData.rentamount.push({
                    amount: "",
                    fromMonth: lastMonth,
                    toMonth: this.reqData.contractDuration,
                });
            } else {
                return
            }
        },
        sendStartEditingEvent(value, name) {
            console.log(value, name);
            // this.pushEditingEvent('start-editing', value, name)
        },
        sendStopEditingEvent(value, name) {
            console.log(value, name);
            // this.pushEditingEvent('stop-editing', value, name)
        },
        sendValueWillChangeEvent(value, name) {
            console.log(value, name);
            // this.pushEditingEvent('value-will-change', value, name)
        },
        amountCommas(event){
            let num = event.target.value;
            if(num !== "" && num !== null){
            num = num.replace(/,/g, '');
            var x=parseInt(num);
            x=x.toString();
            var lastThree = x.substring(x.length-3);
            var otherNumbers = x.substring(0,x.length-3);
            if(otherNumbers != '')
                lastThree = ',' + lastThree;
                event.target.value = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            }else{
              return ""
            }
          },
        putAmountCommas(num){
            if(num !== "" && num !== null){
            num = num.replace(/,/g, '');
            var x=parseInt(num);
            x=x.toString();
            var lastThree = x.substring(x.length-3);
            var otherNumbers = x.substring(0,x.length-3);
            if(otherNumbers != '')
                lastThree = ',' + lastThree;
                return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            }else{
              return ""
            }
          },
        sendValueDidChangeEvent(value, name, attribute, index) {
            // if (name !== "depositTransactionId" && name !== "ownerEmail" && name !== "tenantEmail" && name !== "witnessEmail" && name !== "ownerPoaEmail") {
            //     value = value.toUpperCase();
            // }
            if (
                attribute == "landlord" ||
                attribute == "tenant" ||
                attribute == "rentamount" || attribute == "clause" || attribute == "witness" || attribute == "securityPaymentsMode"
            ) {
                if (name == "ownerCountryCode" && value !== "+91" && (this.currentCity == 'Mumbai' || this.currentCity == 'Pune'|| this.currentCity == 'Mumbai Rural' || this.currentCity =='Thane' || this.currentCity =='Navi Mumbai' || this.currentCity=='Pune Rural')) {
                    this.reqData[attribute][index]["ownerExecutedBy"] = "Power of Attorney";
                    this.reqData[attribute][index]["ownerPOA"].push({
                        "ownerPoaName": "",
                        "ownerPoaPhone": "",
                        "ownerPoaEmail": "",
                        "ownerPoaUID": "",
                        "ownerPoaAddress1": "",
                        "ownerPoaAddress2": "",
                    });
                } else {
                    this.reqData[attribute][index]["ownerExecutedBy"] = ""
                    this.reqData[attribute][index]["ownerPOA"] = []
                }
                if (name == "ownerExecutedBy" && value == "Power of Attorney") {
                    if (this.reqData[attribute][index]["ownerPOA"].length < 1) {
                        this.reqData[attribute][index]["ownerPOA"].push({
                            "ownerPoaName": "",
                            "ownerPoaPhone": "",
                            "ownerPoaEmail": "",
                            "ownerPoaUID": "",
                            "ownerPoaAddress1": "",
                            "ownerPoaAddress2": "",
                        });
                    }
                } else if (name == "ownerExecutedBy" && value == "Self") {
                    this.reqData[attribute][index]["ownerPOA"] = []
                } else if (attribute == "securityPaymentsMode" && name == "depositPaymentMode") {
                    // this.reqData[attribute][index]["depositSecurityAmount"] = "";
                    this.reqData[attribute][index]["depositTransactionId"] = "";
                    this.reqData[attribute][index]["bankName"] = "";
                }
                if (name == "ownerCountryCode") {
                    this.reqData[attribute][index]["ownerUID"] = "";
                    this.reqData[attribute][index]["ownerPassport"] = "";
                }
                if (name == "ownerIdProof") {
                    this.reqData[attribute][index]["ownerUID"] = "";
                    this.reqData[attribute][index]["ownerPassport"] = "";
                }
                this.reqData[attribute][index][name] = value;
            } else if (attribute == "ownerPOA") {
                this.reqData["landlord"][index][attribute][0][name] = value;
            } else if (attribute == 'refundableDeposit') {
                let MHcities = [3486, 3488, 27715]
                if (!MHcities.includes(this.reqData.cityID)) {
                    this.refundableDeposit=value
                } else {
                    const totalDeposit = this.reqData.securityPaymentsMode.length;
                    if (totalDeposit == 1) {
                        this.reqData.securityPaymentsMode[0].depositSecurityAmount = value;
                        this.reqData[attribute] = value;
                    } else {
                        for (var t = 0; t < this.reqData.securityPaymentsMode.length; t++) {
                            this.reqData.securityPaymentsMode[t].depositSecurityAmount = "";
                        }
                        var updateData = getItemFromStorage("agreementData");
                        updateData.securityPaymentsMode = this.reqData.securityPaymentsMode;
                        setItemInStorage("agreementData", updateData);
                        this.reqData[attribute] = value;
                    }
                }
            } else {
                this.reqData[attribute] = value;
            }
            if ("agreementData" in sessionStorage) {
                var oldData = getItemFromStorage("agreementData");
                if (
                    attribute == "landlord" ||
                    attribute == "tenant" ||
                    attribute == "rentamount" || attribute == "clause" || attribute == "witness" || attribute == "securityPaymentsMode"
                ) {
                    oldData[attribute] = this.reqData[attribute];
                }
                // else if (attribute == "ownerPOA") {
                //     oldData["landlord"][index][attribute][0][name] = value;
                // }
                else {
                    oldData[attribute] = value;
                }
                setItemInStorage("agreementData", oldData);
            } else {
                var data = {};
                if (
                    attribute == "landlord" ||
                    attribute == "tenant" ||
                    attribute == "rentamount" || attribute == "clause" || attribute == "witness" || attribute == "securityPaymentsMode"
                ) {
                    data[attribute] = this.reqData[attribute];
                } else if (attribute == "ownerPOA") {
                    data["landlord"][index][attribute][0][name] = value;
                } else {
                    data[attribute] = value;
                }
                setItemInStorage("agreementData", data);
            }
            setItemInStorage('recentUpdatedTime', new Date());
            this.progressBar();
        },
        pushEditingEvent(event, value, name) {
            this.eventsCount += 1;
            this[name].push({
                index: this.eventsCount,
                message: event,
                value: value,
            });
            setTimeout(() => {
                this.events.shift();
            }, 60000);
        },
        async finalSubmit() {
            this.finallySubmitting = true;
            this.panRentValueRequired = false;
            this.openModel = "loader";

            let info = document.getElementsByClassName('properyInfo');
            let mumbaiInfo = document.getElementsByClassName('properyInfoError');
            for (let i = 0; i < info.length; i++) {
                if (info[i].getAttribute('required') == "required" && info[i].value == "") {
                    info[i].classList.add('properyInfoError');
                }
            }
            if(mumbaiInfo.length>0){
                this.openModel = "";
                return;
            }
            let MHcities = [3486, 3488, 27715, 27716, 8999, 3490 ]
            console.log(this.reqData.refundableDeposit,"refundchecking");
            console.log(this.reqData,"check data");
            if (MHcities.includes(this.reqData.cityID)) {
                console.log(this.reqData.refundableDeposit)
                if (this.reqData.refundableDeposit == "") {
                        this.refundableDepositError = true;
                        this.openModel = ""
                        return
                    }
                }

            if (document.getElementsByClassName('vue-xeditable-empty').length > 0) {
                this.openModel = ""
                var element = document.getElementsByClassName('vue-xeditable-empty')[0];
                console.log(element);
                var headerOffset = 155;
                const bodyRect = document.body.getBoundingClientRect().top;
                var elementPosition = element.getBoundingClientRect().top;
                elementPosition = elementPosition - bodyRect;
                var offsetPosition = elementPosition - headerOffset;
                window.parent.postMessage({
                    scrollTo: {
                        top: offsetPosition,
                        behaviour: "smooth"
                    }
                }, '*');
                window.top.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
                return
            }
            if (!MHcities.includes(this.reqData.cityID)) {
                if (parseInt(this.reqData.amount.replace(/,/g, '')) >= 50000) {
                    this.panRentValueRequired = true;
                }
            }
           
            if (!MHcities.includes(this.reqData.cityID)&&document.getElementsByClassName('  ').length > 0) {
                this.openModel = ""
                this.prtybtnActive = true;
                var helpingText = "Property details is mandatory. Please click on the Add Property Details button to enter the details."
                document.getElementById("helpingtext").innerText = helpingText;
                var element1 = document.getElementsByClassName('propertyInfoBtn')[0];
                var headerOffset1 = 155;
                const bodyRect1 = document.body.getBoundingClientRect().top;
                var elementPosition1 = element1.getBoundingClientRect().top;
                var propPo = elementPosition1 - 20;
                document.getElementsByClassName("helpText")[0].setAttribute("style", "display:block;top:" + propPo + "px");
                document.getElementsByClassName("helpText")[0].classList.add('vibrate');
                elementPosition1 = elementPosition1 - bodyRect1;
                var offsetPosition1 = elementPosition1 - headerOffset1;
                window.parent.postMessage({
                    scrollTo: {
                        top: offsetPosition1,
                        behaviour: "smooth"
                    }
                }, '*');
                window.top.scrollTo({
                    top: offsetPosition1,
                    behavior: "smooth"
                });
                return
            }
            if (this.panRentValueRequired && document.getElementsByClassName('vue-xeditable-empty').length > 0) {
                this.openModel = "";
                var helpingText2 = "Pan Card is mandatory for monthly rent > Rs 50,000."
                    // document.getElementsByClassName("helpText")[0].style.display = "block";
                    // document.getElementsByClassName("helpText")[0].classList.add('vibrate');
                document.getElementById("helpingtext").innerText = helpingText2;
                var element3 = document.getElementsByClassName('vue-xeditable-empty')[0];
                var headerOffset3 = 155;
                const bodyRect3 = document.body.getBoundingClientRect().top;
                var elementPosition3 = element3.getBoundingClientRect().top;
                var amountPoup3 = elementPosition3 - 20;
                document.getElementsByClassName("helpText")[0].setAttribute("style", "display:block;top:" + amountPoup3 + "px");
                document.getElementsByClassName("helpText")[0].classList.add('vibrate');
                elementPosition3 = elementPosition3 - bodyRect3;
                var offsetPosition3 = elementPosition3 - headerOffset3;
                window.parent.postMessage({
                    scrollTo: {
                        top: offsetPosition3,
                        behaviour: "smooth"
                    }
                }, '*');
                window.top.scrollTo({
                    top: offsetPosition3,
                    behavior: "smooth"
                });
                return
            } else {
                console.log('not working')
            }
            if (document.getElementsByClassName("errorpopuplabel").length < 1) {
                // console.log(document.getElementsByClassName("errorpopuplabel").length + "second")

    
                
                const _transactionId = await this.agreementSubmit(2);
                if (_transactionId && _transactionId != null) {
                    this.openModel = "";
                    this.$router.push('/agreementView/' + this.$route.params.source + '/' + this.$route.params.token + '/' + _transactionId);
                }
                
          
            }
        },
        async agreementSubmit(index) {
            let cronUpdate = false;
            if (this.shareUrl) {
                return
            }
            if (!index && "recentUpdatedTime" in sessionStorage) {
                let recentUpdatedTime = getItemFromStorage("recentUpdatedTime");
                if (new Date().getTime() - new Date(recentUpdatedTime).getTime() > 200000) {
                    return
                } else {
                    cronUpdate = true;
                }
            } else if (!index) {
                return
            }
            let data = {}
            if ("agreementData" in sessionStorage) {
                let oldData = getItemFromStorage("agreementData");
                if (oldData) {
                    
                    oldData["clause"] = this.reqData["clause"];
                    let newList = [];
                    for (var i = 0; i < this.reqData.amenities.length; i++) {
                        if (this.reqData.amenities[i].Quantity > 0) {
                            newList.push(this.reqData.amenities[i]);
                        }
                    }
                    oldData["amenities"] = newList;
                    oldData["contractDuration"] = this.reqData.contractDuration;
                }
                if ("user" in sessionStorage) {
                    let user = getItemFromStorage("user");
                    data.userId = user.Id;
                }
                data.cronUpdate = cronUpdate;
                if (this.$route.params.id) {
                    data.id = this.$route.params.id;
                } else if ("transactionId" in sessionStorage)
                    data.id = getItemFromStorage("transactionId");
                if (index == 2) {
                    data.status = index
                } else {
                    data.status = 1;
                }
                data.agreement = oldData;
                data.agreementServiceId = getItemFromStorage("agreementId");
                // console.log(data);
                const response = await this.agreementInfo(data);
                if (response.status) {
                    setItemInStorage('transactionId', response.data);
                    return response.data;
                } else {
                    return null
                }
            } else
                return null;
        },
    },
    watch: {
        'reqData.landlord': {
          handler(newVal) {
            this.landlordCount = newVal?.length || 0;
          },
          immediate: true
        },
        'reqData.tenant': {
          handler(newVal) {
            this.tenantCount = newVal?.length || 0;
          },
          immediate: true
        }
      }
}
export default agreementMixins;