<template>
  <div id="app">
          <div class="clipBoard">
            <div class="heading" v-if="!readOnly">
              <figure>
                <img
                  src="../../../assets/images/aggrement-clip.png"
                  alt="clip"
                  class="img-responsive"
                />
              </figure>
              <h2>LEASE DEED</h2>
            </div>
            <div class="priveHeading text-center" v-if="readOnly">
              <h2>LEASE DEED</h2>
            </div>
            <span>{{updateEsignData}}</span>
            <div class="clipBox" v-bind:class="{'box-shadow':!readOnly}">
              <div v-if="readOnly" class="stempView pb-15"><img v-if="stamp" src="https://storage.googleapis.com/sqydocs/escrow/agrementview.jpg" class="img-responsive" /></div>
              <p class="text-center"> This Lease Deed/Rent Agreement is executed at <strong>{{currentCity}}</strong> on day, <strong>{{todayDate}}</strong>. </p>
              <div class="txt text-center">BETWEEN</div>
              <span class="startHere" v-show="!readOnly  && !this.$route.params.id && this.reqData.landlord[0].ownerName ==''"><img src="../../../assets/images/heand-right.svg" class="img-responsive"/></span>
              <p v-for="(owner, index) in reqData.landlord" :key="index">
                <vue-xeditable
                  type="select"
                  name="ownerTitle"
                  attribute="landlord"
                  :value="owner.ownerTitle"
                  :options="titlesOptions"
                  errorText="Age must contain only integer values"
                  :index="index"
                  placeholder="Title"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'LandlordA'+index"
                  :nextTabId="'LandlordB'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{owner.ownerTitle}}</span>
                <span>. </span>
                <vue-xeditable
                  type="text"
                  name="ownerName"
                  attribute="landlord"
                  :index="index"
                  helpingText="Entered name should be same as mentioned on Aadhaar Card"
                  errorText="Name must contain only alphabetical values"
                  :value.sync="owner.ownerName"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :placeholder="owner.ownerTitle=='M/s'?'Company Name' :'Landlord Name'"
                  :enable-editing="true"
                  :currentTabId="'LandlordB'+index"
                  :nextTabId="'LandlordC'+index"
                  pattern="[A-z ]$"
                  validateText="isAlphabet"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{owner.ownerName }} </span>
                <span> , </span>
                <span v-if="owner.ownerTitle=='M/s'"> managed by
                </span>
                <span v-else>
                  <vue-xeditable
                  type="select"
                  name="ownerRelation"
                  attribute="landlord"
                  :value="owner.ownerRelation"
                  :options="relationTo"
                  errorText="please select a value"
                  :index="index"
                  placeholder=""
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'LandlordA'+index"
                  :nextTabId="'LandlordB'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                  <span v-else class="vue-xeditable-value">{{owner.ownerRelation}}</span> 
                </span>
               <span>{{` , `}}</span>
                <!-- }} -->
               <!-- {{owner.ownerTitle=='M/s'?' managed by ':' So/Do/Wo ' }} -->
               <vue-xeditable
                  type="select"
                  name="ownerParentTitle"
                  attribute="landlord"
                  :value="owner.ownerParentTitle"
                  :options="titlesOptions.filter(title=>title!='M/s')"
                  :index="index"
                   placeholder="Title"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'LandlordC'+index"
                  :nextTabId="'LandlordD'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{owner.ownerParentTitle}}</span> 
                <span>. </span>
                <vue-xeditable
                  type="text"
                  name="ownerParentName"
                  attribute="landlord"
                  :index="index"
                  helpingText="Entered name should be same as mentioned on Aadhaar Card"
                  errorText="Name must contain only alphabetical values"
                  :value.sync="owner.ownerParentName"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :placeholder="owner.ownerTitle=='M/s'?' Company director name ': owner.ownerRelation=='W/o'?`Husband's Name`:`Landlord's Parent Name` "
                  :enable-editing="true"
                  :currentTabId="'LandlordD'+index"
                  :nextTabId="'LandlordE'+index"
                  pattern="[A-z ]$"
                  validateText="isAlphabet"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{owner.ownerParentName}}</span>
                
                <span> , having contact number </span>
                <span v-if="owner.ownerCountryCode">
                <vue-xeditable
                  type="select"
                  name="ownerCountryCode"
                  attribute="landlord"
                  :value="owner.ownerCountryCode"
                  :options="countryCodeOptions"
                  errorText="Country code is mandatory"
                  :index="index"
                  placeholder="Country Code"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  tabindex="3"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{owner.ownerCountryCode}}</span>
                - </span>
                <vue-xeditable
                  type="number"
                  maxlength="10"
                  name="ownerMobile"
                  attribute="landlord"
                  :pattern="owner.ownerCountryCode == '+91'?'^[0-9]{10}$':'^[0-9]{7,15}$'"
                  :helpingText="owner.ownerCountryCode == '+91'? 'Please enter 10 digit contact number':'Please enter 7 to 15 digit contact number'"
                  :errorText="owner.ownerCountryCode == '+91'? 'Contact number should be 10 digits':'Contact number should be 7 to 15 digits'"
                  :value.sync="owner.ownerMobile"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'LandlordE'+index"
                  :nextTabId="'LandlordF'+index"
                  :validateText="owner.ownerCountryCode == '+91'?'isIndianMobile':'isMobile'"
                  :placeholder="owner.ownerTitle=='M/s'?'Company Director Contact number*':'Landlord Contact Number*'"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{owner.ownerMobile}}</span>
                <span v-if="!readOnly">, Email Id: </span>
                 <span v-else-if="owner.ownerEmail!== null && owner.ownerEmail !==''">, Email Id: </span>
                <vue-xeditable
                  type="text"
                  name="ownerEmail"
                  attribute="landlord"
                  helpingText="Rent agreement will be sent to the email ID"
                  errorText="Please enter valid email ID"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  :value.sync="owner.ownerEmail"
                  :index="index"
                  :finallySubmitting="finallySubmitting"
                  :required="owner.ownerCountryCode=='+91'? false:true"
                  :currentTabId="'LandlordF'+index"
                  :nextTabId="'LandlordG'+index"
                  :placeholder="owner.ownerTitle=='M/s'?'Company Director Email Id':'Landlord Email Id'"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /> <span v-else class="vue-xeditable-value">{{owner.ownerEmail}}</span> 
                   
                <span v-if="!readOnly">, PAN: </span>
                <span v-else-if="owner.ownerPAN !== null &&owner.ownerPAN !==''">, PAN: </span>
                <span v-if="!readOnly">
                <vue-xeditable
                  type="text"
                  name="ownerPAN"
                  attribute="landlord"
                  pattern="^([a-z-A-Z]{5})([0-9]{4})([a-z-A-Z]{1})$"
                  helpingText="PAN number should have 10 character in the following format e.g. LVPGB9322K"
                  errorText="Please enter a valid 10 digit PAN number (e.g. LVPGB9322K)"
                  :index="index"
                  :value.sync="owner.ownerPAN"
                  :enable-editing="true"
                  :currentTabId="'LandlordG'+index"
                  :required="panRentValueRequired"
                  :finallySubmitting="finallySubmitting"
                  :nextTabId="'LandlordH'+index"
                  class="text-up"
                  validateText="isPan"
                  :placeholder="owner.ownerTitle=='M/s'?'Company Pan Number': 'Landlord PAN Number'"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                /></span><span v-else-if="owner.ownerPAN !== null &&owner.ownerPAN !==''" class="vue-xeditable-value">{{owner.ownerPAN}} </span>
                <span v-if="!readOnly && (owner.ownerCountryCode == '+91' || !owner.ownerCountryCode)"> , UID(Aadhaar No.): </span>
                <span v-else-if="(owner.ownerCountryCode == '+91' || !owner.ownerCountryCode) && (owner.ownerUID !== null &&owner.ownerUID !=='')"> , UID(Aadhaar No.): </span>
                <span v-if="owner.ownerCountryCode && owner.ownerCountryCode !== '+91'">
                  <span v-if="!readOnly">,
                <vue-xeditable
                  type="select"
                  name="ownerIdProof"
                  attribute="landlord"
                  :value="owner.ownerIdProof"
                  :options="idTypeOptions"
                  errorText="ID type is mandatory"
                  :index="index"
                  placeholder="ID Type"
                  :required="false"
                  :finallySubmitting="finallySubmitting"
                  tabindex="3"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                />:</span><span v-else-if="(owner.ownerUID !=='' && owner.ownerUID !==null)||(owner.ownerPassport !=='' && owner.ownerPassport !==null)" class="">,<span> {{owner.ownerIdProof}} </span> : </span>
                </span>
                <!-- <span v-else-if="(owner.ownerUID !=='' && owner.ownerUID !==null && owner.ownerCountryCode !=='+91')||(owner.ownerPassport !=='' && owner.ownerPassport !==null && owner.ownerCountryCode !=='+91') ">,
                     <vue-xeditable
                      type="select"
                      name="ownerIdProof"
                      attribute="landlord"
                      :value="owner.ownerIdProof"
                      :options="idTypeOptions"
                      errorText="ID type is mandatory"
                      :index="index"
                      placeholder="ID Type"
                      :required="true"
                      :finallySubmitting="finallySubmitting"
                      tabindex="3"
                      @start-editing="sendStartEditingEvent"
                      @stop-editing="sendStopEditingEvent"
                      @value-will-change="sendValueWillChangeEvent"
                      @value-did-change="sendValueDidChangeEvent"
                      v-if="!readOnly"
                    /><span v-else class="">{{owner.ownerIdProof}}</span> :
                </span> -->
                <span v-if="owner.ownerCountryCode == '+91' || owner.ownerIdProof == 'UID(Aadhaar No.)'|| !owner.ownerCountryCode">
                <vue-xeditable
                  type="text"
                  name="ownerUID"
                  attribute="landlord"
                  pattern="^([0-9]{12})$"
                  helpingText="Aadhaar should contain exactly 12 digits e.g. 987654321098"
                  errorText="Please enter a valid 12 digit Aadhaar number"
                  :index="index"
                  :value.sync="owner.ownerUID"
                  :enable-editing="true"
                  :required="!owner.ownerCountryCode || owner.ownerCountryCode=='+91' ? false:false"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'LandlordH'+index"
                  :nextTabId="'LandlordI'+index"
                  tabindex="7"
                  validateText="isAadhar"
                  :placeholder="owner.ownerTitle=='M/s'?'Company Director Aadhar Number' :'Landlord Aadhaar Number'"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{owner.ownerUID}}</span></span>
                <span v-if="owner.ownerCountryCode">
                  <span v-if="owner.ownerCountryCode !== '+91' && owner.ownerIdProof == 'Passport No'">
                    <vue-xeditable
                      type="text"
                      name="ownerPassport"
                      attribute="landlord"
                      helpingText="Paasport should contain exactly 9 alphanumeric characters e.g.A20964570"
                      errorText="Please enter a valid 9 alphanumeric characters"
                      :index="index"
                      :value.sync="owner.ownerPassport"
                      :enable-editing="true"
                      :required="false"
    :finallySubmitting="finallySubmitting"
                      :currentTabId="'LandlordH'+index"
                      :nextTabId="'LandlordI'+index"
                      tabindex="7"
                      validateText="isPassport"
                      :placeholder="owner.ownerTitle=='M/s'?'Company Passport Number' :'Landlord Passport Number'"
                      @start-editing="sendStartEditingEvent"
                      @stop-editing="sendStopEditingEvent"
                      @value-will-change="sendValueWillChangeEvent"
                      @value-did-change="sendValueDidChangeEvent"
                      v-if="!readOnly"
                    /><span v-else class="vue-xeditable-value">{{owner.ownerPassport}}</span>
                  </span>
                </span>
                <span> , residing at </span>
                <vue-xeditable
                  type="text"
                  name="ownerAddress1"
                  helpingText="Please enter Address Line 1"
                  attribute="landlord"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :value.sync="owner.ownerAddress1"
                  :enable-editing="true"
                  :currentTabId="'LandlordI'+index"
                  :nextTabId="'LandlordJ'+index"
                  placeholder="Address Line 1"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{owner.ownerAddress1}}</span> ,
                <vue-xeditable
                  type="text"
                  name="ownerAddress2"
                  attribute="landlord"
                  helpingText="Please enter Address Line 2"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :value.sync="owner.ownerAddress2"
                  :enable-editing="true"
                  :currentTabId="'LandlordJ'+index"
                  :nextTabId="'LandlordK'+index"
                  placeholder="Address Line 2"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{owner.ownerAddress2}}</span>
                <span> , </span> 
                <vue-xeditable
                  type="text"
                  name="ownerCity"
                  attribute="landlord"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :index="index"
                  :value.sync="owner.ownerCity"
                  :enable-editing="true"
                  :currentTabId="'LandlordK'+index"
                  :nextTabId="'LandlordL'+index"
                  placeholder="City"
                  validateText="isAlphabet"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{owner.ownerCity}}</span>
                <span>,</span>
                <vue-xeditable
                  type="text"
                  name="ownerPin"
                  attribute="landlord"
                  pattern="^([0-9]{6})$"
                  helpingText="Please enter the 6 digit PIN Code"
                  errorText="PIN Code must be 6 digits"
                  :index="index"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :value.sync="owner.ownerPin"
                  :enable-editing="true"
                  :currentTabId="'LandlordL'+index"
                  :nextTabId="'LandlordM'+index"
                  placeholder="Pincode"
                  validateText="isPincode"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{owner.ownerPin}}</span> (Hereinafter called the <span class="text-bold">Lessor-{{ index +1   }}  and/ or the First Party</span>)
                <span class="addNewBox" v-if="index>0 && !readOnly "><span @click="removeLanloard(index)"><em class="icon-delete"></em></span></span>
              </p>
              <div class="col-md-12 text-center pb-15 clearBoth" v-if="!readOnly">
                <button @click="addLandlord()" class="addNew"><em class="icon-add"></em> Add Landlord</button>
              </div>
              <div class="txt text-center pt-0">AND</div>
              <p v-for="(tenant, index) in reqData.tenant" :key="index + 'A'">
                <vue-xeditable
                  type="select"
                  name="tenantTitle"
                  attribute="tenant"
                  :value="tenant.tenantTitle"
                  :options="titlesOptions"
                  errorText="Age must contain only integer values"
                  :index="index"
                  placeholder="Title"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'TenantA'+index"
                  :nextTabId="'TenantB'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{tenant.tenantTitle}}</span>
                <span>. </span>
                <vue-xeditable
                  type="text"
                  name="tenantName"
                  helpingText="Entered name should be same as mentioned on Aadhaar Card"
                  errorText="Name must contain only alphabetical values"
                  pattern="[A-Za-z ]{3}"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  attribute="tenant"
                  :index="index"
                  :placeholder="tenant.tenantTitle=='M/s'?'Company Name':'Tenant Name'"
                  :value.sync="tenant.tenantName"
                  :enable-editing="true"
                  :currentTabId="'TenantB'+index"
                  :nextTabId="'TenantC'+index"
                  validateText="isAlphabet"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{tenant.tenantName}}</span>
                <span> , </span>
                <span v-if="tenant.tenantTitle=='M/s'">, managed by ,
                </span>
                <span v-else>
                  <vue-xeditable
                  type="select"
                  name="tenantRelation"
                  attribute="tenant"
                  :value="tenant.tenantRelation"
                  :options="relationTo"
                  errorText="Age must contain only integer values"
                  :index="index"
                  placeholder="Title"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'TenantC'+index"
                  :nextTabId="'TenantD'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /> 
                <span v-else class="vue-xeditable-value">{{tenant.tenantRelation}}</span> 
                 <span> , </span>
                </span>
                <vue-xeditable
                  type="select"
                  name="tenantParentTitle"
                  attribute="tenant"
                  :value="tenant.tenantParentTitle"
                  :options="titlesOptions.filter(title=>title!='M/s')"
                  errorText="Age must contain only integer values"
                  :index="index"
                  placeholder="Title"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :currentTabId="'TenantC'+index"
                  :nextTabId="'TenantD'+index"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{tenant.tenantParentTitle}}</span> 
                <span>. </span>
                <vue-xeditable
                  type="text"
                  name="tenantParentName"
                  attribute="tenant"
                  :index="index"
                  helpingText="Entered name should be same as mentioned on Aadhaar Card"
                  errorText="Name must contain only alphabetical values"
                  :value.sync="tenant.tenantParentName"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :placeholder="tenant.tenantTitle=='M/s'?'Company Director Name':tenant.tenantRelation=='W/o'? `Husband's Name`:`Tenant's Parent Name`"
                  :enable-editing="true"
                  :currentTabId="'TenantD'+index"
                  :nextTabId="'TenantE'+index"
                  pattern="[A-z ]$"
                  validateText="isAlphabet"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                />
                <span v-else class="vue-xeditable-value">{{tenant.tenantParentName}}</span>
                <span> , having contact number </span>
                <span v-if="true">
            <vue-xeditable
              type="select"
              name="tenantCountryCode"
              attribute="tenant"
              :value="tenant.tenantCountryCode"
              :options="countryCodeOptions"
              errorText="Country code is mandatory"
              :index="index"
              placeholder="Country Code"
              :required="true"
              :finallySubmitting="finallySubmitting"
              tabindex="3"
              @start-editing="sendStartEditingEvent"
              @stop-editing="sendStopEditingEvent"
              @value-will-change="sendValueWillChangeEvent"
              @value-did-change="sendValueDidChangeEvent"
              v-if="!readOnly"
            />
            <span v-else class="vue-xeditable-value">{{
              tenant.tenantCountryCode
            }}</span>
            -
          </span>
                <vue-xeditable
                  type="number"
                  maxlength="10"
                  name="tenantMobile"
                  helpingText="Please enter 10 digit contact number"
                  errorText="Contact number should be 10 digits"
                  attribute="tenant"
                  pattern="^[0-9]{10}$"
                  :required="true"
:finallySubmitting="finallySubmitting"
                  :value.sync="tenant.tenantMobile"
                  :index="index"
                  :currentTabId="'TenantE'+index"
                  :nextTabId="'TenantF'+index"
                  :placeholder="tenant.tenantTitle=='M/s'?'Company Director Mobile Number':'Tenant Contact Number'"
                  validateText="isIndianMobile"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantMobile}}</span>
                <span v-if="!readOnly">, Email Id: </span>
               <span v-else-if="tenant.tenantEmail && tenant.tenantEmail!==null && tenant.tenantEmail!==''">, Email Id: </span>
                <vue-xeditable
                  type="text"
                  name="tenantEmail"
                  helpingText="Rent agreement will be sent to the email ID"
                  errorText="Please enter valid email ID"
                  attribute="tenant"
                  :finallySubmitting="finallySubmitting"
                  :required="false"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  :value.sync="tenant.tenantEmail"
                  :index="index"
                  :currentTabId="'TenantF'+index"
                  :nextTabId="'TenantG'+index"
                  :placeholder="tenant.tenantTitle=='M/s'?'Company Director Email Id':'Tenant Email Id'"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantEmail}}</span>
                <span v-if="!readOnly"> ,  PAN: </span>
                 <span v-else-if="tenant.tenantPAN && tenant.tenantPAN!=null && tenant.tenantPAN!==''"> , PAN: </span>
                <vue-xeditable
                  type="text"
                  name="tenantPAN"
                  attribute="tenant"
                  helpingText="PAN number should have 10 character in the following format e.g. LVPGB9322K"
                  errorText="Please enter a valid 10 digit PAN number (e.g. LVPGB9322K)"
                  :index="index"
                  pattern="^([a-z-A-Z]{5})([0-9]{4})([a-z-A-Z]{1})$"
                  :placeholder="tenant.tenantTitle=='M/s'?'Company Pan Number':'Tenant PAN Number'"
                  :required="panRentValueRequired"
:finallySubmitting="finallySubmitting"
                  :value.sync="tenant.tenantPAN"
                  :enable-editing="true"
                  :currentTabId="'TenantG'+index"
                  :nextTabId="'TenantH'+index"
                  validateText="isPan"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantPAN}}</span>
                <span v-if="!readOnly"> , UID(Aadhaar No.): </span>
                <span v-else-if="readOnly && tenant.tenantUID !== null && tenant.tenantUID !== ''"> , UID(Aadhaar No.): </span>
                <vue-xeditable
                  type="text"
                  name="tenantUID"
                  helpingText="Aadhaar should contain exactly 12 digits e.g. 987654321098"
                  errorText="Please enter a valid 12 digit Aadhaar number"
                  attribute="tenant"
                  :index="index"
                  pattern="^([0-9]{12})$"
                  :placeholder="tenant.tenantTitle=='M/s'?'Company Director Aadhaar Number':'Tenant Aadhaar Number'"
                  :required="false"
                  :finallySubmitting="finallySubmitting"
                  :value.sync="tenant.tenantUID"
                  :enable-editing="true"
                  :currentTabId="'TenantH'+index"
                  :nextTabId="'TenantI'+index"
                  validateText="isAadhar"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else-if="readOnly && tenant.tenantUID !== null && tenant.tenantUID !== ''" class="vue-xeditable-value">{{tenant.tenantUID}}</span><span> , residing at </span>
                <vue-xeditable
                  type="text"
                  name="tenantAddress1"
                  helpingText="Please enter Address Line 1"
                  attribute="tenant"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  :index="index"
                  :value.sync="tenant.tenantAddress1"
                  :enable-editing="true"
                  :currentTabId="'TenantI'+index"
                  :nextTabId="'TenantJ'+index"
                  placeholder="Address Line 1"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantAddress1}}</span>
                <span> , </span
                ><vue-xeditable
                  type="text"
                  name="tenantAddress2"
                  helpingText="Please enter Address Line 2"
                  attribute="tenant"
                  :required="true"
:finallySubmitting="finallySubmitting"
                  :index="index"
                  :value.sync="tenant.tenantAddress2"
                  :enable-editing="true"
                  :currentTabId="'TenantJ'+index"
                  :nextTabId="'TenantK'+index"
                  placeholder="Address Line 2"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantAddress2}}</span>
                <span> , </span
                ><vue-xeditable
                  type="text"
                  name="tenantCity"
                  attribute="tenant"
                  :required="true"
:finallySubmitting="finallySubmitting"
                  :index="index"
                  :value.sync="tenant.tenantCity"
                  :enable-editing="true"
                  :currentTabId="'TenantK'+index"
                  :nextTabId="'TenantL'+index"
                  placeholder="City"
                  validateText="isAlphabet"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantCity}}</span>
                <span> , </span
                ><vue-xeditable
                  type="text"
                  name="tenantPin"
                  helpingText="Please enter the 6 digit PIN Code"
                  errorText="PIN Code must be 6 digits"
                  attribute="tenant"
                  pattern="^([0-9]{6})$"
                  :required="true"
:finallySubmitting="finallySubmitting"
                  :index="index"
                  :value.sync="tenant.tenantPin"
                  :enable-editing="true"
                  :currentTabId="'TenantL'+index"
                  :nextTabId="'TenantM'+index"
                  placeholder="Pincode"
                  validateText="isPincode"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{tenant.tenantPin}}</span> (Hereinafter called the <span class="text-bold"> Lessee-{{index +1  }} and/ or Second Party</span>)
                <span class="addNewBox" v-if="index>0 && !readOnly"><span @click="removeTenant(index)"><em class="icon-delete"></em></span></span>
              </p>
              <div class="col-md-12 text-center pb-15" v-if="!readOnly">
                <button @click="addTenant()" class="addNew"><em class="icon-add"></em>Add Tenant</button>
              </div>
              <p>For the purpose hereof,the Lessor-{{Array.from({length: landlordCount}, (_, i) => i + 1).join(',')  }} shall be collectively referred to as {{this.landlordLabel}} and {{this.tenantLabel}}-{{Array.from({length: tenantCount}, (_, i) => i + 1).join(',')  }} shall be collectively referred to as {{this.tenantLabel}} <br/>
                The Lessor and Lessee are referred to collectively as the <span class="text-bold">"Parties"</span> and individually the <span class="text-bold">"Party"</span> as the context may require.</p>
              <p v-if="reqData.propertyType !=''">
                Whereas the Lessor is the lawful owner in possession of the 
                <span  class=" spn-bl text-bold">{{reqData.propertyType}}</span> 
                  unit of  
                  <span  class="spn-bl text-bold"> {{reqData.propertyAreaType}} </span>  
                  <span  class="spn-bl text-bold"> {{reqData.builtUparea}} </span> 
                  <span  class="spn-bl text-bold"> {{reqData.buildUpType}} </span> 
                  <span  class="spn-bl text-bold"> {{reqData.buildUpunit}} </span> 
                  bearing 
                  <span v-if="reqData.buildingType =='Residential'" >House No.</span>
                  <span v-if="reqData.buildingType =='Commercial'" >Shop No. </span>
                  <span  class="spn-bl text-bold">{{reqData.flatNo}} </span> 
                  ,  situated on the Floor No
                <span  class="spn-bl text-bold">{{reqData.floorNumber}}</span> 
                <span v-if="reqData.buildingName !== null && reqData.buildingName !==''">
                  , Building known as 
                  <span  class="spn-bl text-bold">{{reqData.buildingName}}  </span>
                </span>
                , Address 1:
                <span  class="spn-bl text-bold">{{reqData.propertyAddressone}} , </span>
                Locality:
                <span  class="spn-bl text-bold">{{reqData.locality}} ,</span>
                City:
                <span  class="spn-bl text-bold">{{reqData.propertyCity}} ,</span>
                <span  class="spn-bl text-bold">{{reqData.propertyPinCode}}</span>
       
                
    <!-- (House type -  <span class="spn-bl">
                  <vue-xeditable
                  type="select"
                  name="propertyHouseType"
                  attribute="propertyHouseType"
                  :value="reqData.propertyHouseType"
                  :options="houseTypeOption"
                  placeholder="Unit Configuration"
                  :required="true"
:finallySubmitting="finallySubmitting"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{reqData.propertyHouseType}}</span>
                </span>) -->
              
                (hereinafter called the <span class="text-bold">'demised premises'</span>). The expression Lessor and Lessee shall mean and include their respective heirs, successors, representatives, and assignees.
              </p>
              <p class="text-center" v-if="reqData.propertyType =='' && !readOnly"><button @click="getPropertyDetails"  class="btn propertyInfoBtn" :class="{'btn-danger': prtybtnActive,'btn-primary':!prtybtnActive}">Add Property Details</button></p>
              <p class="text-center" v-if="reqData.propertyType !='' && !readOnly" ><button @click="getPropertyDetails"  class="btn btn-primary" :class="{'btn-danger': prtybtnActive,'btn-primary':!prtybtnActive}">Edit Property Details</button></p>
              <p>
                Whereas on the request of the Lessee, the Lessor has agreed to let out the Demised Premises to the LESSEE, and the LESSEE has agreed to take it on rent for a period of 
                <vue-xeditable
                  type="select"
                  name="contractDuration"
                  attribute="contractDuration"
                  placeholder="Agreement Duration"
                  :value="reqData.contractDuration"
                  :options="agreementDuration"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{reqData.contractDuration}}</span>
                Month(s) 
                w.e.f.
                <vue-xeditable
                  type="date"
                  name="startDate"
                  attribute="startDate"
                  :value.sync="reqData.startDate"
                  :enable-editing="true"
                  :required="true"
                  :finallySubmitting="finallySubmitting"
                  placeholder="Agreement Start Date"
                  @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                  v-if="!readOnly"
                /><span v-else class="vue-xeditable-value">{{reqData.startDate}}</span>
                for its bonafide {{reqData.buildingType}} use. Whereas the LESSOR has represented that the Demised Premises is free from all encumbrances and the LESSOR has a clean and unrestricted right to the Demised Premises. Whereas the Lessor and Lessee both represented that they are legally competent to enter into this Lease Agreement on the terms and conditions contained herein.
                <!-- for its bonafide residential/commercial use. Whereas the LESSOR has represented that the Demised Premises is free from all encumbrances and the LESSOR has a clean and unrestricted right to the Demised Premises. Whereas the Lessor and Lessee both represented that they are legally competent to enter into this Lease Agreement on the terms and conditions contained herein. -->
              </p>
              <div class="txt normal">
                Now, these present witnesses as under:
              </div>
              <ol class="orderList">
                     <li v-for="(clause,index) in reqData.clause" :key="index+'F'" class="clauseInput">
                       <div v-if="clause.tag&&clause.tag==1">
                          That the second party shall pay the monthly rent of Rs 
                  <vue-xeditable
                        type="text"
                        name="amount"
                        attribute="amount"
                        pattern="([0-9])"
                        :value.sync="reqData.amount"
                        :required="true"
                        :finallySubmitting="finallySubmitting"
                        placeholder="Rent Amount"
                        :currentTabId="'RentAmount'"
                        :nextTabId="'paymentIn'"
                        :enable-editing="true"
                        validateText="isNumeric"
                        :amountFormat="true"
                        @start-editing="sendStartEditingEvent"
                        @stop-editing="sendStopEditingEvent"
                        @value-will-change="sendValueWillChangeEvent"
                        @value-did-change="sendValueDidChangeEvent"
                        v-if="!readOnly"
                      /><span v-else class="vue-xeditable-value">{{reqData.amount}}</span>
                      <span class="ml12">
              <vue-xeditable
                type="select"
                placeholder="select type "
                :value="reqData.maintenance"
                :options="maintenanceOption"
                :index="index"
                attribute = "maintenance"
                :currentTabId="'maintenance-check'"
                :nextTabId="'paymentIn'"
                :enable-editing="true"
                :required="true"
                errorText ="Please select a value"
                :finallySubmitting="finallySubmitting"
                @start-editing="sendStartEditingEvent"
                  @stop-editing="sendStopEditingEvent"
                  @value-will-change="sendValueWillChangeEvent"
                  @value-did-change="sendValueDidChangeEvent"
                v-if="!readOnly"
              />
              <span v-else class="vue-xeditable-value">{{reqData.maintenance}}</span>
            </span>
                  in respect of the Demised Premises located at
                   <!-- Flat no:  -->
                    <!-- <span class="text-bold">{{reqData.flatNo}}</span>
                    <span v-if="!readOnly && reqData.buildingName !== null && reqData.buildingName !==''">
                      , 
                    </span>
                    <span v-else-if="reqData.buildingName !== null && reqData.buildingName !==''">
                      ,
                    </span> -->
                    <!-- <span class="text-bold"> {{reqData.buildingName}}</span>, -->
                     <span  class="spn-bl text-bold">{{reqData.propertyAddressone}} .</span>
                    <!-- <span class="text-bold">{{reqData.locality}}</span>,  
                    <span class="text-bold">{{reqData.propertyCity}}</span>, 
                    <span class="text-bold">{{reqData.propertyPinCode}}</span>  -->

                   <!-- ( -->
                     <!-- House type - <span  class="spn-bl text-bold">{{reqData.propertyHouseType}}</span>,  -->
                   <!-- Floor no: <span  class="spn-bl text-bold">{{reqData.floorNumber}}</span>) -->
                   <!-- <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span> -->
                       </div>
                      <div v-else-if="clause.tag&&clause.tag==2">
                    The rent shall be paid per month in advance through advance rental on or before the
                   <vue-xeditable
                    type="number"
                    name="paymentIn"
                    attribute="paymentIn"
                    :value.sync="reqData.paymentIn"
                    :enable-editing="true"
                    :required="true"
                    :finallySubmitting="finallySubmitting"
                    :currentTabId="'paymentIn'"
                    :nextTabId="'incrementPercentage'"
                    placeholder="Rent Payment Day"
                    validateText="isNumeric"
                    @start-editing="sendStartEditingEvent"
                    @stop-editing="sendStopEditingEvent"
                    @value-will-change="sendValueWillChangeEvent"
                    @value-did-change="sendValueDidChangeEvent"
                    v-if="!readOnly"
                  /><span v-else class="vue-xeditable-value">{{reqData.paymentIn}}</span>
                 <sup>{{ ordinalVal}}</sup>  day of each English calendar month. In case of TDS deduction, 
                  the Lessee shall furnish the TDS certificate to the Lessor at the end of each calendar quarter well within time so 
                  as to enable the Lessor to file his income tax return within the stipulated timeframe. 
                  Each of the parties will bear the consequences for any non-compliance on account of the tax liability of its part.
                    <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
                      </div>
                      <div v-else-if="clause.tag&&clause.tag==3">
                        That after the expiry of the Lease term, monthly rent shall be increased at the escalation of 
                  <vue-xeditable
                    type="number"
                    name="incrementPercentage"
                    attribute="incrementPercentage"
                    :value="reqData.incrementPercentage"
                    :required="true"
                    :finallySubmitting="finallySubmitting"
                    :currentTabId="'incrementPercentage'"
                    :nextTabId="'refundableDeposit'"
                    placeholder="Rent Increment"
                    validateText="isNumeric"
                    @start-editing="sendStartEditingEvent"
                    @stop-editing="sendStopEditingEvent"
                    @value-will-change="sendValueWillChangeEvent"
                    @value-did-change="sendValueDidChangeEvent"
                    v-if="!readOnly"
                  /><span v-else class="vue-xeditable-value">{{reqData.incrementPercentage}}</span> 
                  % or at  mutually agreed by both parties at the time of renewal in the discussion as per prevailing market conditions.
                    <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
                      </div>
                      <div v-else-if="clause.tag&&clause.tag==4">
                  That the second party has deposited a sum of Rs 
                  <vue-xeditable
                    type="text"
                    name="refundableDeposit"
                    attribute="refundableDeposit"
                    :value.sync="reqData.refundableDeposit"
                    :enable-editing="true"
                    :required="true"
                    :finallySubmitting="finallySubmitting"
                    :currentTabId="'refundableDeposit'"
                    :nextTabId="'noticePeriod'"
                    placeholder="Refundable Deposit"
                    validateText="isNumeric"
                    :amountFormat="true"
                    @start-editing="sendStartEditingEvent"
                    @stop-editing="sendStopEditingEvent"
                    @value-will-change="sendValueWillChangeEvent"
                    @value-did-change="sendValueDidChangeEvent"
                    v-if="!readOnly"
                  /><span v-else class="vue-xeditable-value">{{reqData.refundableDeposit}}</span>
                  as interest free refundable security 	deposit, which will be refunded (Interest Free) within seven (7) days of the Lessee vacating the Demised Premises 	after deducting any outstanding rent, electricity, water, sewerage and maintenance charges, bills, etc., if any, which are payable by the Lessee at the time of vacating the Demised Premises. Lessor shall have the right to adjust all the dues including but not limited to rent, maintenance, electricity, water, sewerage, etc. of the notice period from the Refundable Security deposit.
                   <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
                      </div>
                    <div   v-else-if="clause.tag&&clause.tag==5">
                  <!-- That any outstanding amount towards rental or maintenance, if not settled by the Lessee, will be adjusted from the security deposit of Rs  
                  <span class="text-bold">{{reqData.refundableDeposit}}</span>
                  provided to the LESSOR.  -->
                  The notice period to be served by either party would be of 
                  <vue-xeditable
                    type="number"
                    name="noticePeriod"
                    attribute="noticePeriod"
                    :value="reqData.noticePeriod"
                    :required="true"
                    :finallySubmitting="finallySubmitting"
                    :currentTabId="'noticePeriod'"
                    placeholder="Notice Period"
                    validateText="isNumeric"
                    @start-editing="sendStartEditingEvent"
                    @stop-editing="sendStopEditingEvent"
                    @value-will-change="sendValueWillChangeEvent"
                    @value-did-change="sendValueDidChangeEvent"
                    v-if="!readOnly"
                  /><span v-else class="vue-xeditable-value">{{reqData.noticePeriod}}</span>
                   Day(s). Either the LESSOR or the LESSEE may terminate this agreement without assigning any reasons whatsoever by giving one month's advance notice to the other party.
                  <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
            
                    </div>
                    <div v-else-if="clause.tag&&clause.tag==6">
                        The Lessor will ensure that all outstanding bills/ charges on the above said demised premises on account of electricity, 
                  water, and any other incidentals prior to the start of lease from
                  <span class="text-bold">{{reqData.startDate}}</span>
                  are settled and paid.
                    <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
                    </div>
                    <div v-else-if="clause.tag&&clause.tag==7">
                         <span>
                    <strong>Lock in period:</strong> Both the parties have agreed to set a lock-in period of
                  </span>
                  <vue-xeditable
                    type="select"
                    name="lockIn"
                    :required="true"
                    :finallySubmitting="finallySubmitting"
                    attribute="lockIn"
                    placeholder="Lock-in Period"
                    :value="reqData.lockIn"
                    :options="lockInOptions"
                    :currentTabId="'lockIn'"
                    :nextTabId="'noticePeriod'"
                    @start-editing="sendStartEditingEvent"
                    @stop-editing="sendStopEditingEvent"
                    @value-will-change="sendValueWillChangeEvent"
                    @value-did-change="sendValueDidChangeEvent"
                    v-if="!readOnly"
                  /><span v-else class="vue-xeditable-value">{{reqData.lockIn}}</span>
                  <span>
                    Month(s) during which neither the Lessor shall ask the Lessee to vacate the premises, nor the Lessee shall vacate the premises on his/her own during the Lock-in period. In spite of this mandatory 	clause, if the Lessee leaves the premises for whatsoever reason, he shall pay to the Lessor license fee for the remaining lock-in period at the rate of License Fees agreed upon in the Agreement. On the other hand, Lessor shall compensate the Lessee for loss and inconvenience caused 	to the Lessee if he has been asked to vacate the premises.</span>
                 <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
                    </div>
                    <div v-else-if="clause.tag&&clause.tag==8">
                      It is further agreed between the parties that in case of any dispute the {{reqData.city}} court shall have the exclusive jurisdiction over the disputes.
                      </div>
                      
                  <div v-else-if="clause.clauses.length>0 && !clause.custom">
                    <span class="addNewBox " v-if="!readOnly && clause.frontAddBtn" :class="{'lastOne':clause.frontAddBtn}">
                       <span class="edit" @click="addClause(index)"><em class="icon-add" ></em></span>
                       <!-- <span @click="deleteClause(index)"><em class="icon-delete" ></em></span> -->
                     </span> 
                    <span v-html="clause.clauses"></span>
                    <span class="addNewBox" v-if="!readOnly && !clause.frontAddBtn">
                      <span class="edit tooltip" @click="addClause(index)"><em class="icon-add" ></em> <span class="tooltiptext">Please click here to add a custom term in the agreement</span></span>
                      <span @click="deleteClause(index)"><em class="icon-delete" ></em></span>
                    </span>
                  </div>
                  <div v-else class="customClause">
                    <vue-xeditable
                      type="textarea"
                      name="clauses"
                      placeholder="Your Custom Clause"
                      attribute="clause"
                      :value.sync="clause.clauses"
                      :required="true"
                      :finallySubmitting="finallySubmitting"
                      :index="index"
                      @start-editing="sendStartEditingEvent"
                      @stop-editing="sendStopEditingEvent"
                      @value-will-change="sendValueWillChangeEvent"
                      @value-did-change="sendValueDidChangeEvent"
                      v-if="!readOnly"
                    /><span v-else class="customcl">{{clause.clauses}}</span><span class="addNewBox" v-if="!readOnly"><span class="edit" @click="addClause(index)" title=""><em class="icon-add" ></em></span><span @click="deleteClause(index)"><em class="icon-delete" ></em></span></span>
                  </div>
                </li>
                <!-- <li v-for="(clause,index) in reqData.customClause" :key="index+'F'" class="clauseInput">
                  <div class="customClause">
                    <vue-xeditable
                      type="textarea"
                      name="clauses"
                      placeholder="Your Custom Clause"
                      attribute="clause"
                      :required="true"
                      :finallySubmitting="finallySubmitting"
                      :index="index"
                      :value.sync="clause.clauses"
                      validateText="isMiscellanous"
                      pattern="^[0-9a-zA-Z\s,-,/,.,-]+$"
                      :clauseLimit="false"
                      @start-editing="sendStartEditingEvent"
                      @stop-editing="sendStopEditingEvent"
                      @value-will-change="sendValueWillChangeEvent"
                      @value-did-change="sendValueDidChangeEvent"
                      v-if="!readOnly"
                    /><span v-else class="customcl">{{clause.clauses}}</span><span class="addNewBox" v-if="!readOnly"><span class="edit" @click="addClause(index)" title=""><em class="icon-add" ></em></span><span @click="deleteClause(index)"><em class="icon-delete" ></em></span></span>
                  </div>
                </li> -->
              </ol>
              <!-- <p>IN WITNESS WHEREOF the parties hereto have set and subscribed their respective signatures by way of putting thumb impression electronic signature hereto in the presence of witness, who are identifying the executants, on the day, month and year first above written.</p> -->
              <div class="txt text-center">ANNEXURE 1</div> 
              <p>Items provided by the LESSOR at the time of execution of Lease Deed between the LESSOR and the LESSEE are as follows:</p>

              <div class="table-responsive">
                <table class="table amenitiesTable">
                <tr>
                  <th>Sr no</th>
                  <th>Item</th>
                  <th width="150">Quantity</th>
                </tr>
                <tbody>
                <tr v-for="(utilities, index) in reqData.amenities" :key="index+'C'">
                  <td>{{index+1}}</td>
                  <td v-if="utilities.Custom == true && !readOnly">
                    <input type="text" :id="'utility'+index" :disabled="false"  @keypress.enter="utilityBlurEvent('utility',index,$event)"  @blur="utilityBlurEvent('utility',index,$event)" v-model.trim="utilities.Name" class="form-control text-bold amnty">
                    </td>
                  <td v-else>{{utilities.Name}}</td>
                  
                  <td class="addnum">
                    <span class="numberAction" v-if="!readOnly">
                      <span @click="countdcreement(index)" class="action ">-</span>
                      <!-- <input type="hidden" :value="reqData[index].name = utility.Name" >
                      <input type="hidden" :value="reqData[index].quantity" > -->
                      <span> {{ utilities.Quantity }} </span>
                      <span @click="countIncreement(index)" class="action">+</span>
                    </span>
                    <span v-else class="vue-xeditable-value">{{ utilities.Quantity }}</span>
                    <span class="addNewBox" v-if="index>16 && !readOnly"><span @click="deleteAmenity(index)"><em class="icon-delete"></em></span></span>
                  </td>
                </tr>
                </tbody>               
              </table>
              <div class="col-md-12 text-center pt-15 pb-15 text-center" v-if="!readOnly">
                    <button @click="addamenity()" class="addNew"><em class="icon-add"></em> Add Custom Utilities</button>
              </div>
                <!-- <div class="col-md-12 pt-15 pb-15">
                  <p class="text-left">Signature of the Lessor / First Party</p>
                  <p class="text-left">Signature of the Lessor / Second Party</p>
                </div> -->
              </div>
            </div>
            
          </div>

        <div class="pt-15 pb-15 text-center" v-if="!readOnly">
          <button class="btn btn-primary" @click="finalSubmit">Submit</button>
        </div>
        <div class="modal-main" :class="{active : prtyinfoActive == true}" v-if="!readOnly">
          <div class="modal-dialog propertyInfo" id="propertyDetails" >
            <div class="modal-header">
              <h3>Add Property Details</h3>
              <em class="icon-close" @click="closePropertyInfo"></em>
            </div>
            <div class="modelBody">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Building Type
                      <span class="star">*</span>
                    </label>
                    <select class="form-control properyInfo" :required="true" v-model="buildingType" >
                        <option value="Residential" selected>Residential</option>
                        <option value="Commercial">Commercial</option>
                    </select>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Property Type
                      <span class="star">*</span>
                    </label>
                    <select class="form-control" v-if="buildingType == '' ">
                        <option selected value="">Select Property Type</option>
                    </select>
                    <select class="form-control properyInfo" v-if="buildingType == 'Commercial'" v-model="propertyType" name="propertyType" :required="true" @change="bullringTypeCheck" @blur="stopEditing" >
                        <option selected value="">Select Property Type</option>
                        <option v-for="(commercial, index) in commercialOption" :key="index"  :value="commercial">{{commercial}}</option>
                    </select>
                    <select class="form-control properyInfo" v-if="buildingType == 'Residential'" v-model="propertyType" :required="true" @change="bullringTypeCheck" @blur="stopEditing">
                        <option selected value="">Select Property Type</option>
                        <option v-for="(resid, index) in residentialOption" :key="index"  :value="resid">{{resid}}</option>
                    </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Area Type
                      <span class="star">*</span>
                    </label>
                    <select class="form-control properyInfo" v-model="propertyAreaType" name="propertyAreaType">
                        <option selected value="">Select Plot Area Type</option>
                        <option v-for="(plotType, index) in buildupAreaOption" :key="index" :value="plotType">{{plotType}}</option>
                    </select>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                  <div class="row">
                    <div class="col-md-8 col-lg-8 col-sm-12">
                      <label>Area
                        <span class="star">*</span>
                      </label>
                      <input type="number"
                      name="builtUparea"
                      pattern="[0-9]"
                      @blur="stopEditing"
                      v-model="builtUparea"
                      :required="true"
                      placeholder="Area"
                      validateText="isNumeric"
                      @value-will-change="sendValueWillChangeEvent"
                      @value-did-change="sendValueDidChangeEvent"
                      v-if="!readOnly" class="form-control properyInfo"/>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-12">
                      <label>UOM
                        <span class="star">*</span>
                      </label>
                      <select class="form-control properyInfo" v-model="buildUpunit" name="buildUpunit">
                        <option v-for="(unit, index) in buildUpoption" :selected='reqData.buildUpunit == unit ? true:false' :value="unit" :key="index">{{unit}}</option>
                    </select>
                    </div>
                  </div>
                    
                </div>
              </div>
              <div class="row">
                 <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Pin Code
                      <span class="star">*</span>
                    </label>
                    <input type="text"
                    name="propertyPinCode"
                    @blur="stopEditing"
                    v-model="propertyPinCode"
                    class="form-control properyInfo"
                    pattern="^([0-9]{6})$"
                    helpingText="Please enter the 6 digit PIN Code"
                    errorText="PIN Code must be 6 digits"
                    :required="true"
                    v-on:keypress="filterInput"
                    maxlength="6"
                    placeholder="Pincode"
                    validateText="isNumeric"
                    />
                </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Locality
                      <span class="star">*</span>
                    </label>
                    <vue-autosuggest
                      v-model="locality"
                      :suggestions="localityFilteredOptions"
                      @focus="focusMe"
                      @click="clickHandler"
                      @input="onLocalityInputChange"
                      @selected="onSelectedLocality"
                      @blur="stopEditing"
                      :get-suggestion-value="getLocalitySuggestionValue"
                      :input-props="{id:'autosuggest__input',name:'locality', placeholder:'Choose a locality', class:'form-control properyInfo',required:true}">
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                      <div>{{suggestion.item.name}}</div>
                    </div>
                  </vue-autosuggest>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Building Name</label>
                    <vue-autosuggest
                      name='buildingName'
                      v-model="buildingName"
                      :suggestions="societyFilteredOptions"
                      @focus="focusMe"
                      @click="clickHandler"
                      @input="onSocietyInputChange"
                      @selected="onSelectedSociety"
                      @blur="stopEditing"
                      :required="false"
                      validateText="isAlphaNumeric"
                      :get-suggestion-value="getSocietySuggestionValue"
                      v-if="!readOnly"
                      :input-props="{id:'autosuggest__input',name:'buildingName', placeholder:'Choose Society / Building',class:'form-control properyInfo buildingSet'}">
                      <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                        <div>{{suggestion.item.name}}</div>
                      </div>
                    </vue-autosuggest>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>Floor Number </label>
                    <input
                    type="number"
                    @blur="stopEditing"
                    name="floorNumber"
                    pattern="[0-9]"
                    v-model="floorNumber"
                    :required="false"
                    validateText="isNumeric"
                    placeholder="Floor Number"
                    class="form-control properyInfo"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label><span class="text-bold" v-if="reqData.buildingType =='Residential'" >House</span>
                    <span class="text-bold" v-if="reqData.buildingType =='Commercial'" >Shop</span> Number</label>
                    <input
                    type="text"
                    @blur="stopEditing"
                    pattern="[a-z-1-9]"
                    name="flatNo"
                    v-model="flatNo"
                    :required="false"
                    validateText="isAlphaNumeric"
                    placeholder="House Number"
                    class="form-control properyInfo"/>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 form-group">
                    <label>City<span class="star">*</span></label>
                    <input
                    type="text"
                    name="propertyCity"
                    class="form-control properyInfo"
                    helpingText="Please enter the City Name"
                    attribute="propertyCity"
                    v-model="propertyCity"
                    :required="true"
                    validateText="isAlphabet"
                    placeholder="City" disabled
                  />
                </div>
              </div>
              <div class="row">
                 <div class="col-md-12 col-lg-12 col-sm-12 form-group">
                  <label> Complete Address<span class="star">*</span> </label>
                  <textarea 
                  :required="true"
                   @blur="stopEditing"
                    v-model="propertyAddressone" 
                    class="form-control properyInfo" rows="2" 
                    placeholder="Complete Address">
                    </textarea>
                </div>
              </div>
              
            </div>
            <div class="modal-footer text-center">
              <button class="btn" @click="getPropertyInfo">Submit</button>
            </div>
          </div>
        </div>
           <overlay :openModel="openModel"></overlay>
   </div>
</template>

<script>
import agreementMixins from './agreementMixins';
export default {
  mixins:[agreementMixins],
  data(){
    return{
      reqData:{
          clause: [ 
              {
            "clauses":"",
            "tag":1
              },
            {
            "clauses":"",
            "tag":2 
              },
            {
            "clauses":"",
            "tag":3
              },
            {
            "clauses":"",
            "tag":4
              },
            {
            "clauses":"",
            "tag":5
              },
            {
            "clauses":"",
            "tag":6
              },
            {
            "clauses":"",
            "tag":7
              },
            {
            "clauses":"",
            "tag":8
              },
            {
            "clauses":"That the electricity and water charges after the start of the lease will be paid timely and regularly every month by the Lessee	as per actual bills provided by the service provider. A copy of the payment receipts will be 	provided by the Lessee to the Lessor on demand. In the unlikely instance that the connection/s for electricity or water is disconnected due to non- payment or negligence of the LESSEE, the charges to restoring such connections shall be borne fully by the LESSEE and if not paid the same can be deducted from the security deposit provided to the Lessor."
          },{
            "clauses":"That the Lessor shall hand over the Premises to the Lessee in a habitable condition. The detailed list of items provided as part of this lease is enumerated as ANNEXURE 1 to this Deed."
          },{
            "clauses":"That in case any damage is caused by the LESSEE to the aforesaid premises, fixtures, fittings, etc.(except normal wear and tear), the LESSEE shall be liable to make good the same to ensure that those is restored in the same condition as they were at the time of signing of this lease other than the changes made by the LESSEE with the consent of the LESSOR. In case of LESSEE fails to do so, LESSOR shall be entitled to deduct the costs of doing the same from the interest free security deposit."
          },{
            "clauses":"That the Second Party shall have no right, to make any addition, alteration in the Demised Premises except furnishings. The Lessor shall not be liable to pay any charges against the expenses incurred by the Lessee for any additional furnishing at the Demised Premises."
          },{
            "clauses":"That the Second Party shall have no right to sub-let the whole or part of Demised Premises to 	any other person or entity at any time. Further, The Lessor or his authorized representative has the right to visit the Demised Premises on any working day during business hours after taking the Lessee's permission."
          },{
            "clauses":"That the Demised Premises shall be used by the Lessee in a cordial and civilized manner without causing any nuisance or disturbance to the other occupants of the building complex. The Lessee shall use the Demised Premises for its bonafide legal purposes and shall not do or cause any actions or activities of illegal, immoral, unsocial nature in the Demised Premises and will not create any nuisance to the neighborhood in any manner whatsoever."
          },{
            "clauses":"That day-to-day repair such as fuses, leakage of water taps, replacement of defective MCBs, Bulbs, Tube lights, Tube light Fittings, connecting sanitary pipes, doors, door locks, etc. shall be borne by the Lessee at its own costs. However, major repairs such as leakage from the  wall ceiling, etc. would be rectified by the Lessor on the request of Lessee."
          },{
            "clauses":"That in case the Lessee defaults in payment of rent for any month or commits any breach of  	any of the terms and conditions of this deed, the LESSOR shall be entitled to get back the possession of the Demised Premises after providing reasonable notice to the Lessee."
          },{
            "clauses":"That the Lessee shall make sure that all the payments shall have been made on regular basis by them to the Service Providers or Government Authorities on account of any services utilized by them or taxes/levies demanded by or payable to Government Authorities on account of their transactions. The Lessee shall be liable at all times even after vacation of the Demised Premises for dues if any arising of the tenure of occupation of the Lessee which is liable to be paid by the Lessee."
          },{
            "clauses":"That after the expiry of this Lease Deed, if the LESSOR does not wish to renew it or to continue further, the Lessee is bound to vacate the Demised Premises immediately upon expiry of the lease to the Lessor in all good faith and handover the peaceful possession to the Lessor failing which the Lessee will pay damages at the rate of double the monthly rent as stipulated in this Deed."
          },{
            "clauses":"That the Lessor/ his authorized agents shall acknowledge and give valid & duly stamped receipts as and when requested by the LESSEE as conclusive proof of rent payments on demand from the Lessee."
          },{
            "clauses":"This Deed shall be governed by and interpreted in accordance with the laws of India. All disputes, differences, disagreements, controversies or claims arising out of or in connection with this Deed, including the validity, effect, and interpretation thereof, shall, at the request of either party, be referred to the sole arbitrator mutually appointed by both the parties, who shall conduct the arbitration proceedings in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, or any amendment or statutory modification or replacement or substitution thereof, Any award made by the arbitrator shall be final and binding on the Parties. The cost and expenses of the arbitration proceedings, including fees of the arbitrators, shall be borne equally by the Parties. The venue of arbitration shall be as mutually decided by the parties."
          },{
            "clauses":"Without any prejudice to a Party's other rights and claims under this Lease or otherwise, if one party breaches any of its representations, obligations, warranties, covenants or undertakings or violates any provision hereunder, it shall indemnify and keep the other Party and/or service providers harmless against all direct damages and costs suffered or borne by it or them thereby including but not limited to costs incurred in defending all claims/actions, or proceedings that may arise or may be otherwise necessary to ensure exclusive, quiet and peaceful access, occupation and use of the Leased Premises in accordance with this Deed. Without prejudice to other rights enjoyed by either Party (non- defaulting Party) under the Deed and Applicable Laws, the other Party (Defaulting Party) shall be responsible for and will indemnify against all claims, demands, suits, proceedings, judgments, direct damage, and relevant costs that the non-defaulting Party may suffer or incur in connection with loss of life and/or personal injury to the occupants of the Leased Premises and/or damage to the Building if the same arise from any wrongful/negligent act or omission of the defaulting Party."
          },{
            "clauses":"<strong>Force Majeure:</strong> If the whole or any part of the Demised Premises shall at any time during the term of the lease be destroyed or damaged due to any force majeure circumstances including storm, tempest, flood, Act of God, an act of terrorism, war or any other irresistible force or the Lessee is deprived of the use of the Demised Premises for reasons not attributable to the Lessee, the Lessor hereby undertakes to restore the Demised Premises as expeditiously as possible or, as the case may be, to remove the impediment in its use and occupation as expeditiously as possible. Notwithstanding the foregoing, upon the happening of any such event as aforesaid, the Lessee shall not be liable to pay Lease Rent during the period the Lessee is deprived of the use of the Demised Premises or any part thereof. The Lessee shall also have the option to terminate the Lease after the event by giving one month's notice and without payment of any rent in lieu thereof and without incurring any liability to pay any other amount whatsoever to the Lessor."
          },{
            "clauses":"<strong>Notice:</strong> Any notice or communication to be addressed by one party to the other shall be in 	writing and shall be served at the addresses as given hereinabove by registered post with 	AID or at such other addresses as may be notified in writing by one party to another. Any change in such address shall be promptly notified to the other party in writing."
          },{
            "clauses":"<strong>Miscellaneous:</strong><ol class='pl-15'> <li>This Lease Agreement constitutes the entire agreement concerning the subject matter hereof between the Lessor and the Lessee and supersedes any prior representations or 	agreements, whether written or oral between the Lessor and Lessee. No modification or amendment of this Agreement or waiver of any of its provisions shall be binding upon the parties hereto unless made in writing and duly signed by both Parties.</li><li>If any provision of this Agreement is held to be unenforceable, the remaining provisions of this Agreement shall continue to remain in full force and effect.</li><li>Leegality.com is the e-witness to this rental agreement.</li></ol>",
            "frontAddBtn":true
          }],
        }
    }
  },
};

</script>

<style>
/* #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 90%;
    padding: 15px 25px;
    margin: 60px auto;
    position: relative;
    display: block;
  } */

  .ml12{margin-left:5px }

.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}

.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}

.events-banner .prefix {
  color: #858585;
}

.events-banner .content {
  font-weight: bold;
  color: #273080;
}

.events-banner .value {
  color: #3a5831;
  font-size: larger;
}

.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}
.vue-xeditable.enabled {
  position: relative;
  display: contents;
}

h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}

h4 {
  margin-bottom: 0;
  color: #404040;
}

a {
  color: #07c;
}

pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}

.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre code,
pre tt {
  background-color: transparent;
  border: none;
}
.star{
  color: red;
  font-size: 18px;
}

</style>
